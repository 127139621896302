import { type GaCentileWeight } from './types'
export const gaCentileWeights: GaCentileWeight[] = [
  {
    ga: 140,
    values: [
      {
        centile: 3,
        value: 278,
      },
      {
        centile: 5,
        value: 283,
      },
      {
        centile: 10,
        value: 291,
      },
      {
        centile: 25,
        value: 305,
      },
      {
        centile: 50,
        value: 321,
      },
      {
        centile: 75,
        value: 337,
      },
      {
        centile: 90,
        value: 353,
      },
      {
        centile: 95,
        value: 363,
      },
      {
        centile: 97,
        value: 369,
      },
    ],
  },
  {
    ga: 141,
    values: [
      {
        centile: 3,
        value: 286,
      },
      {
        centile: 5,
        value: 291,
      },
      {
        centile: 10,
        value: 299,
      },
      {
        centile: 25,
        value: 313,
      },
      {
        centile: 50,
        value: 329,
      },
      {
        centile: 75,
        value: 346,
      },
      {
        centile: 90,
        value: 362,
      },
      {
        centile: 95,
        value: 372,
      },
      {
        centile: 97,
        value: 379,
      },
    ],
  },
  {
    ga: 142,
    values: [
      {
        centile: 3,
        value: 293,
      },
      {
        centile: 5,
        value: 298,
      },
      {
        centile: 10,
        value: 306,
      },
      {
        centile: 25,
        value: 321,
      },
      {
        centile: 50,
        value: 337,
      },
      {
        centile: 75,
        value: 355,
      },
      {
        centile: 90,
        value: 372,
      },
      {
        centile: 95,
        value: 382,
      },
      {
        centile: 97,
        value: 389,
      },
    ],
  },
  {
    ga: 143,
    values: [
      {
        centile: 3,
        value: 300,
      },
      {
        centile: 5,
        value: 306,
      },
      {
        centile: 10,
        value: 314,
      },
      {
        centile: 25,
        value: 329,
      },
      {
        centile: 50,
        value: 346,
      },
      {
        centile: 75,
        value: 364,
      },
      {
        centile: 90,
        value: 381,
      },
      {
        centile: 95,
        value: 392,
      },
      {
        centile: 97,
        value: 399,
      },
    ],
  },
  {
    ga: 144,
    values: [
      {
        centile: 3,
        value: 308,
      },
      {
        centile: 5,
        value: 314,
      },
      {
        centile: 10,
        value: 322,
      },
      {
        centile: 25,
        value: 338,
      },
      {
        centile: 50,
        value: 355,
      },
      {
        centile: 75,
        value: 374,
      },
      {
        centile: 90,
        value: 391,
      },
      {
        centile: 95,
        value: 402,
      },
      {
        centile: 97,
        value: 409,
      },
    ],
  },
  {
    ga: 145,
    values: [
      {
        centile: 3,
        value: 316,
      },
      {
        centile: 5,
        value: 322,
      },
      {
        centile: 10,
        value: 331,
      },
      {
        centile: 25,
        value: 346,
      },
      {
        centile: 50,
        value: 364,
      },
      {
        centile: 75,
        value: 383,
      },
      {
        centile: 90,
        value: 401,
      },
      {
        centile: 95,
        value: 412,
      },
      {
        centile: 97,
        value: 420,
      },
    ],
  },
  {
    ga: 146,
    values: [
      {
        centile: 3,
        value: 324,
      },
      {
        centile: 5,
        value: 330,
      },
      {
        centile: 10,
        value: 339,
      },
      {
        centile: 25,
        value: 355,
      },
      {
        centile: 50,
        value: 374,
      },
      {
        centile: 75,
        value: 393,
      },
      {
        centile: 90,
        value: 412,
      },
      {
        centile: 95,
        value: 423,
      },
      {
        centile: 97,
        value: 431,
      },
    ],
  },
  {
    ga: 147,
    values: [
      {
        centile: 3,
        value: 332,
      },
      {
        centile: 5,
        value: 338,
      },
      {
        centile: 10,
        value: 348,
      },
      {
        centile: 25,
        value: 364,
      },
      {
        centile: 50,
        value: 383,
      },
      {
        centile: 75,
        value: 403,
      },
      {
        centile: 90,
        value: 422,
      },
      {
        centile: 95,
        value: 434,
      },
      {
        centile: 97,
        value: 442,
      },
    ],
  },
  {
    ga: 148,
    values: [
      {
        centile: 3,
        value: 341,
      },
      {
        centile: 5,
        value: 347,
      },
      {
        centile: 10,
        value: 357,
      },
      {
        centile: 25,
        value: 373,
      },
      {
        centile: 50,
        value: 393,
      },
      {
        centile: 75,
        value: 414,
      },
      {
        centile: 90,
        value: 433,
      },
      {
        centile: 95,
        value: 445,
      },
      {
        centile: 97,
        value: 453,
      },
    ],
  },
  {
    ga: 149,
    values: [
      {
        centile: 3,
        value: 349,
      },
      {
        centile: 5,
        value: 356,
      },
      {
        centile: 10,
        value: 365,
      },
      {
        centile: 25,
        value: 383,
      },
      {
        centile: 50,
        value: 403,
      },
      {
        centile: 75,
        value: 424,
      },
      {
        centile: 90,
        value: 444,
      },
      {
        centile: 95,
        value: 457,
      },
      {
        centile: 97,
        value: 465,
      },
    ],
  },
  {
    ga: 150,
    values: [
      {
        centile: 3,
        value: 358,
      },
      {
        centile: 5,
        value: 364,
      },
      {
        centile: 10,
        value: 375,
      },
      {
        centile: 25,
        value: 392,
      },
      {
        centile: 50,
        value: 413,
      },
      {
        centile: 75,
        value: 435,
      },
      {
        centile: 90,
        value: 455,
      },
      {
        centile: 95,
        value: 468,
      },
      {
        centile: 97,
        value: 477,
      },
    ],
  },
  {
    ga: 151,
    values: [
      {
        centile: 3,
        value: 367,
      },
      {
        centile: 5,
        value: 374,
      },
      {
        centile: 10,
        value: 384,
      },
      {
        centile: 25,
        value: 402,
      },
      {
        centile: 50,
        value: 423,
      },
      {
        centile: 75,
        value: 446,
      },
      {
        centile: 90,
        value: 467,
      },
      {
        centile: 95,
        value: 480,
      },
      {
        centile: 97,
        value: 489,
      },
    ],
  },
  {
    ga: 152,
    values: [
      {
        centile: 3,
        value: 376,
      },
      {
        centile: 5,
        value: 383,
      },
      {
        centile: 10,
        value: 394,
      },
      {
        centile: 25,
        value: 412,
      },
      {
        centile: 50,
        value: 434,
      },
      {
        centile: 75,
        value: 457,
      },
      {
        centile: 90,
        value: 479,
      },
      {
        centile: 95,
        value: 492,
      },
      {
        centile: 97,
        value: 501,
      },
    ],
  },
  {
    ga: 153,
    values: [
      {
        centile: 3,
        value: 385,
      },
      {
        centile: 5,
        value: 392,
      },
      {
        centile: 10,
        value: 403,
      },
      {
        centile: 25,
        value: 423,
      },
      {
        centile: 50,
        value: 445,
      },
      {
        centile: 75,
        value: 469,
      },
      {
        centile: 90,
        value: 491,
      },
      {
        centile: 95,
        value: 505,
      },
      {
        centile: 97,
        value: 514,
      },
    ],
  },
  {
    ga: 154,
    values: [
      {
        centile: 3,
        value: 395,
      },
      {
        centile: 5,
        value: 402,
      },
      {
        centile: 10,
        value: 413,
      },
      {
        centile: 25,
        value: 433,
      },
      {
        centile: 50,
        value: 456,
      },
      {
        centile: 75,
        value: 480,
      },
      {
        centile: 90,
        value: 503,
      },
      {
        centile: 95,
        value: 517,
      },
      {
        centile: 97,
        value: 527,
      },
    ],
  },
  {
    ga: 155,
    values: [
      {
        centile: 3,
        value: 404,
      },
      {
        centile: 5,
        value: 412,
      },
      {
        centile: 10,
        value: 423,
      },
      {
        centile: 25,
        value: 444,
      },
      {
        centile: 50,
        value: 467,
      },
      {
        centile: 75,
        value: 492,
      },
      {
        centile: 90,
        value: 516,
      },
      {
        centile: 95,
        value: 530,
      },
      {
        centile: 97,
        value: 540,
      },
    ],
  },
  {
    ga: 156,
    values: [
      {
        centile: 3,
        value: 414,
      },
      {
        centile: 5,
        value: 422,
      },
      {
        centile: 10,
        value: 434,
      },
      {
        centile: 25,
        value: 455,
      },
      {
        centile: 50,
        value: 479,
      },
      {
        centile: 75,
        value: 504,
      },
      {
        centile: 90,
        value: 529,
      },
      {
        centile: 95,
        value: 544,
      },
      {
        centile: 97,
        value: 553,
      },
    ],
  },
  {
    ga: 157,
    values: [
      {
        centile: 3,
        value: 424,
      },
      {
        centile: 5,
        value: 432,
      },
      {
        centile: 10,
        value: 444,
      },
      {
        centile: 25,
        value: 466,
      },
      {
        centile: 50,
        value: 491,
      },
      {
        centile: 75,
        value: 517,
      },
      {
        centile: 90,
        value: 542,
      },
      {
        centile: 95,
        value: 557,
      },
      {
        centile: 97,
        value: 567,
      },
    ],
  },
  {
    ga: 158,
    values: [
      {
        centile: 3,
        value: 435,
      },
      {
        centile: 5,
        value: 443,
      },
      {
        centile: 10,
        value: 455,
      },
      {
        centile: 25,
        value: 477,
      },
      {
        centile: 50,
        value: 503,
      },
      {
        centile: 75,
        value: 530,
      },
      {
        centile: 90,
        value: 555,
      },
      {
        centile: 95,
        value: 571,
      },
      {
        centile: 97,
        value: 581,
      },
    ],
  },
  {
    ga: 159,
    values: [
      {
        centile: 3,
        value: 445,
      },
      {
        centile: 5,
        value: 453,
      },
      {
        centile: 10,
        value: 466,
      },
      {
        centile: 25,
        value: 489,
      },
      {
        centile: 50,
        value: 515,
      },
      {
        centile: 75,
        value: 542,
      },
      {
        centile: 90,
        value: 569,
      },
      {
        centile: 95,
        value: 585,
      },
      {
        centile: 97,
        value: 596,
      },
    ],
  },
  {
    ga: 160,
    values: [
      {
        centile: 3,
        value: 456,
      },
      {
        centile: 5,
        value: 464,
      },
      {
        centile: 10,
        value: 478,
      },
      {
        centile: 25,
        value: 501,
      },
      {
        centile: 50,
        value: 527,
      },
      {
        centile: 75,
        value: 556,
      },
      {
        centile: 90,
        value: 582,
      },
      {
        centile: 95,
        value: 599,
      },
      {
        centile: 97,
        value: 610,
      },
    ],
  },
  {
    ga: 161,
    values: [
      {
        centile: 3,
        value: 467,
      },
      {
        centile: 5,
        value: 475,
      },
      {
        centile: 10,
        value: 489,
      },
      {
        centile: 25,
        value: 513,
      },
      {
        centile: 50,
        value: 540,
      },
      {
        centile: 75,
        value: 569,
      },
      {
        centile: 90,
        value: 597,
      },
      {
        centile: 95,
        value: 614,
      },
      {
        centile: 97,
        value: 625,
      },
    ],
  },
  {
    ga: 162,
    values: [
      {
        centile: 3,
        value: 478,
      },
      {
        centile: 5,
        value: 487,
      },
      {
        centile: 10,
        value: 501,
      },
      {
        centile: 25,
        value: 525,
      },
      {
        centile: 50,
        value: 553,
      },
      {
        centile: 75,
        value: 583,
      },
      {
        centile: 90,
        value: 611,
      },
      {
        centile: 95,
        value: 629,
      },
      {
        centile: 97,
        value: 640,
      },
    ],
  },
  {
    ga: 163,
    values: [
      {
        centile: 3,
        value: 489,
      },
      {
        centile: 5,
        value: 498,
      },
      {
        centile: 10,
        value: 513,
      },
      {
        centile: 25,
        value: 537,
      },
      {
        centile: 50,
        value: 566,
      },
      {
        centile: 75,
        value: 597,
      },
      {
        centile: 90,
        value: 626,
      },
      {
        centile: 95,
        value: 644,
      },
      {
        centile: 97,
        value: 656,
      },
    ],
  },
  {
    ga: 164,
    values: [
      {
        centile: 3,
        value: 501,
      },
      {
        centile: 5,
        value: 510,
      },
      {
        centile: 10,
        value: 525,
      },
      {
        centile: 25,
        value: 550,
      },
      {
        centile: 50,
        value: 580,
      },
      {
        centile: 75,
        value: 611,
      },
      {
        centile: 90,
        value: 641,
      },
      {
        centile: 95,
        value: 659,
      },
      {
        centile: 97,
        value: 671,
      },
    ],
  },
  {
    ga: 165,
    values: [
      {
        centile: 3,
        value: 512,
      },
      {
        centile: 5,
        value: 522,
      },
      {
        centile: 10,
        value: 537,
      },
      {
        centile: 25,
        value: 563,
      },
      {
        centile: 50,
        value: 594,
      },
      {
        centile: 75,
        value: 626,
      },
      {
        centile: 90,
        value: 656,
      },
      {
        centile: 95,
        value: 675,
      },
      {
        centile: 97,
        value: 688,
      },
    ],
  },
  {
    ga: 166,
    values: [
      {
        centile: 3,
        value: 524,
      },
      {
        centile: 5,
        value: 534,
      },
      {
        centile: 10,
        value: 550,
      },
      {
        centile: 25,
        value: 576,
      },
      {
        centile: 50,
        value: 608,
      },
      {
        centile: 75,
        value: 641,
      },
      {
        centile: 90,
        value: 672,
      },
      {
        centile: 95,
        value: 691,
      },
      {
        centile: 97,
        value: 704,
      },
    ],
  },
  {
    ga: 167,
    values: [
      {
        centile: 3,
        value: 537,
      },
      {
        centile: 5,
        value: 547,
      },
      {
        centile: 10,
        value: 562,
      },
      {
        centile: 25,
        value: 590,
      },
      {
        centile: 50,
        value: 622,
      },
      {
        centile: 75,
        value: 656,
      },
      {
        centile: 90,
        value: 688,
      },
      {
        centile: 95,
        value: 707,
      },
      {
        centile: 97,
        value: 721,
      },
    ],
  },
  {
    ga: 168,
    values: [
      {
        centile: 3,
        value: 549,
      },
      {
        centile: 5,
        value: 559,
      },
      {
        centile: 10,
        value: 575,
      },
      {
        centile: 25,
        value: 604,
      },
      {
        centile: 50,
        value: 636,
      },
      {
        centile: 75,
        value: 671,
      },
      {
        centile: 90,
        value: 704,
      },
      {
        centile: 95,
        value: 724,
      },
      {
        centile: 97,
        value: 738,
      },
    ],
  },
  {
    ga: 169,
    values: [
      {
        centile: 3,
        value: 562,
      },
      {
        centile: 5,
        value: 572,
      },
      {
        centile: 10,
        value: 589,
      },
      {
        centile: 25,
        value: 618,
      },
      {
        centile: 50,
        value: 651,
      },
      {
        centile: 75,
        value: 687,
      },
      {
        centile: 90,
        value: 720,
      },
      {
        centile: 95,
        value: 741,
      },
      {
        centile: 97,
        value: 755,
      },
    ],
  },
  {
    ga: 170,
    values: [
      {
        centile: 3,
        value: 575,
      },
      {
        centile: 5,
        value: 585,
      },
      {
        centile: 10,
        value: 602,
      },
      {
        centile: 25,
        value: 632,
      },
      {
        centile: 50,
        value: 666,
      },
      {
        centile: 75,
        value: 703,
      },
      {
        centile: 90,
        value: 737,
      },
      {
        centile: 95,
        value: 758,
      },
      {
        centile: 97,
        value: 773,
      },
    ],
  },
  {
    ga: 171,
    values: [
      {
        centile: 3,
        value: 588,
      },
      {
        centile: 5,
        value: 599,
      },
      {
        centile: 10,
        value: 616,
      },
      {
        centile: 25,
        value: 646,
      },
      {
        centile: 50,
        value: 682,
      },
      {
        centile: 75,
        value: 719,
      },
      {
        centile: 90,
        value: 754,
      },
      {
        centile: 95,
        value: 776,
      },
      {
        centile: 97,
        value: 791,
      },
    ],
  },
  {
    ga: 172,
    values: [
      {
        centile: 3,
        value: 601,
      },
      {
        centile: 5,
        value: 612,
      },
      {
        centile: 10,
        value: 630,
      },
      {
        centile: 25,
        value: 661,
      },
      {
        centile: 50,
        value: 697,
      },
      {
        centile: 75,
        value: 735,
      },
      {
        centile: 90,
        value: 772,
      },
      {
        centile: 95,
        value: 794,
      },
      {
        centile: 97,
        value: 809,
      },
    ],
  },
  {
    ga: 173,
    values: [
      {
        centile: 3,
        value: 614,
      },
      {
        centile: 5,
        value: 626,
      },
      {
        centile: 10,
        value: 644,
      },
      {
        centile: 25,
        value: 676,
      },
      {
        centile: 50,
        value: 713,
      },
      {
        centile: 75,
        value: 752,
      },
      {
        centile: 90,
        value: 789,
      },
      {
        centile: 95,
        value: 812,
      },
      {
        centile: 97,
        value: 828,
      },
    ],
  },
  {
    ga: 174,
    values: [
      {
        centile: 3,
        value: 628,
      },
      {
        centile: 5,
        value: 640,
      },
      {
        centile: 10,
        value: 659,
      },
      {
        centile: 25,
        value: 691,
      },
      {
        centile: 50,
        value: 729,
      },
      {
        centile: 75,
        value: 769,
      },
      {
        centile: 90,
        value: 807,
      },
      {
        centile: 95,
        value: 831,
      },
      {
        centile: 97,
        value: 847,
      },
    ],
  },
  {
    ga: 175,
    values: [
      {
        centile: 3,
        value: 642,
      },
      {
        centile: 5,
        value: 654,
      },
      {
        centile: 10,
        value: 674,
      },
      {
        centile: 25,
        value: 707,
      },
      {
        centile: 50,
        value: 746,
      },
      {
        centile: 75,
        value: 787,
      },
      {
        centile: 90,
        value: 826,
      },
      {
        centile: 95,
        value: 850,
      },
      {
        centile: 97,
        value: 866,
      },
    ],
  },
  {
    ga: 176,
    values: [
      {
        centile: 3,
        value: 657,
      },
      {
        centile: 5,
        value: 669,
      },
      {
        centile: 10,
        value: 689,
      },
      {
        centile: 25,
        value: 723,
      },
      {
        centile: 50,
        value: 763,
      },
      {
        centile: 75,
        value: 805,
      },
      {
        centile: 90,
        value: 844,
      },
      {
        centile: 95,
        value: 869,
      },
      {
        centile: 97,
        value: 886,
      },
    ],
  },
  {
    ga: 177,
    values: [
      {
        centile: 3,
        value: 671,
      },
      {
        centile: 5,
        value: 684,
      },
      {
        centile: 10,
        value: 704,
      },
      {
        centile: 25,
        value: 739,
      },
      {
        centile: 50,
        value: 780,
      },
      {
        centile: 75,
        value: 823,
      },
      {
        centile: 90,
        value: 863,
      },
      {
        centile: 95,
        value: 889,
      },
      {
        centile: 97,
        value: 906,
      },
    ],
  },
  {
    ga: 178,
    values: [
      {
        centile: 3,
        value: 686,
      },
      {
        centile: 5,
        value: 699,
      },
      {
        centile: 10,
        value: 719,
      },
      {
        centile: 25,
        value: 755,
      },
      {
        centile: 50,
        value: 797,
      },
      {
        centile: 75,
        value: 841,
      },
      {
        centile: 90,
        value: 883,
      },
      {
        centile: 95,
        value: 909,
      },
      {
        centile: 97,
        value: 926,
      },
    ],
  },
  {
    ga: 179,
    values: [
      {
        centile: 3,
        value: 701,
      },
      {
        centile: 5,
        value: 714,
      },
      {
        centile: 10,
        value: 735,
      },
      {
        centile: 25,
        value: 772,
      },
      {
        centile: 50,
        value: 814,
      },
      {
        centile: 75,
        value: 860,
      },
      {
        centile: 90,
        value: 902,
      },
      {
        centile: 95,
        value: 929,
      },
      {
        centile: 97,
        value: 947,
      },
    ],
  },
  {
    ga: 180,
    values: [
      {
        centile: 3,
        value: 716,
      },
      {
        centile: 5,
        value: 730,
      },
      {
        centile: 10,
        value: 751,
      },
      {
        centile: 25,
        value: 789,
      },
      {
        centile: 50,
        value: 832,
      },
      {
        centile: 75,
        value: 879,
      },
      {
        centile: 90,
        value: 922,
      },
      {
        centile: 95,
        value: 950,
      },
      {
        centile: 97,
        value: 968,
      },
    ],
  },
  {
    ga: 181,
    values: [
      {
        centile: 3,
        value: 731,
      },
      {
        centile: 5,
        value: 745,
      },
      {
        centile: 10,
        value: 767,
      },
      {
        centile: 25,
        value: 806,
      },
      {
        centile: 50,
        value: 851,
      },
      {
        centile: 75,
        value: 898,
      },
      {
        centile: 90,
        value: 943,
      },
      {
        centile: 95,
        value: 971,
      },
      {
        centile: 97,
        value: 989,
      },
    ],
  },
  {
    ga: 182,
    values: [
      {
        centile: 3,
        value: 747,
      },
      {
        centile: 5,
        value: 761,
      },
      {
        centile: 10,
        value: 784,
      },
      {
        centile: 25,
        value: 823,
      },
      {
        centile: 50,
        value: 869,
      },
      {
        centile: 75,
        value: 917,
      },
      {
        centile: 90,
        value: 963,
      },
      {
        centile: 95,
        value: 992,
      },
      {
        centile: 97,
        value: 1011,
      },
    ],
  },
  {
    ga: 183,
    values: [
      {
        centile: 3,
        value: 763,
      },
      {
        centile: 5,
        value: 778,
      },
      {
        centile: 10,
        value: 801,
      },
      {
        centile: 25,
        value: 841,
      },
      {
        centile: 50,
        value: 888,
      },
      {
        centile: 75,
        value: 937,
      },
      {
        centile: 90,
        value: 984,
      },
      {
        centile: 95,
        value: 1013,
      },
      {
        centile: 97,
        value: 1033,
      },
    ],
  },
  {
    ga: 184,
    values: [
      {
        centile: 3,
        value: 779,
      },
      {
        centile: 5,
        value: 794,
      },
      {
        centile: 10,
        value: 818,
      },
      {
        centile: 25,
        value: 859,
      },
      {
        centile: 50,
        value: 907,
      },
      {
        centile: 75,
        value: 958,
      },
      {
        centile: 90,
        value: 1006,
      },
      {
        centile: 95,
        value: 1035,
      },
      {
        centile: 97,
        value: 1055,
      },
    ],
  },
  {
    ga: 185,
    values: [
      {
        centile: 3,
        value: 796,
      },
      {
        centile: 5,
        value: 811,
      },
      {
        centile: 10,
        value: 835,
      },
      {
        centile: 25,
        value: 877,
      },
      {
        centile: 50,
        value: 926,
      },
      {
        centile: 75,
        value: 978,
      },
      {
        centile: 90,
        value: 1027,
      },
      {
        centile: 95,
        value: 1058,
      },
      {
        centile: 97,
        value: 1078,
      },
    ],
  },
  {
    ga: 186,
    values: [
      {
        centile: 3,
        value: 812,
      },
      {
        centile: 5,
        value: 828,
      },
      {
        centile: 10,
        value: 853,
      },
      {
        centile: 25,
        value: 896,
      },
      {
        centile: 50,
        value: 946,
      },
      {
        centile: 75,
        value: 999,
      },
      {
        centile: 90,
        value: 1049,
      },
      {
        centile: 95,
        value: 1080,
      },
      {
        centile: 97,
        value: 1101,
      },
    ],
  },
  {
    ga: 187,
    values: [
      {
        centile: 3,
        value: 829,
      },
      {
        centile: 5,
        value: 845,
      },
      {
        centile: 10,
        value: 871,
      },
      {
        centile: 25,
        value: 914,
      },
      {
        centile: 50,
        value: 966,
      },
      {
        centile: 75,
        value: 1020,
      },
      {
        centile: 90,
        value: 1071,
      },
      {
        centile: 95,
        value: 1103,
      },
      {
        centile: 97,
        value: 1125,
      },
    ],
  },
  {
    ga: 188,
    values: [
      {
        centile: 3,
        value: 847,
      },
      {
        centile: 5,
        value: 863,
      },
      {
        centile: 10,
        value: 889,
      },
      {
        centile: 25,
        value: 934,
      },
      {
        centile: 50,
        value: 986,
      },
      {
        centile: 75,
        value: 1042,
      },
      {
        centile: 90,
        value: 1094,
      },
      {
        centile: 95,
        value: 1127,
      },
      {
        centile: 97,
        value: 1149,
      },
    ],
  },
  {
    ga: 189,
    values: [
      {
        centile: 3,
        value: 864,
      },
      {
        centile: 5,
        value: 881,
      },
      {
        centile: 10,
        value: 907,
      },
      {
        centile: 25,
        value: 953,
      },
      {
        centile: 50,
        value: 1007,
      },
      {
        centile: 75,
        value: 1063,
      },
      {
        centile: 90,
        value: 1117,
      },
      {
        centile: 95,
        value: 1151,
      },
      {
        centile: 97,
        value: 1173,
      },
    ],
  },
  {
    ga: 190,
    values: [
      {
        centile: 3,
        value: 882,
      },
      {
        centile: 5,
        value: 899,
      },
      {
        centile: 10,
        value: 926,
      },
      {
        centile: 25,
        value: 973,
      },
      {
        centile: 50,
        value: 1027,
      },
      {
        centile: 75,
        value: 1085,
      },
      {
        centile: 90,
        value: 1140,
      },
      {
        centile: 95,
        value: 1175,
      },
      {
        centile: 97,
        value: 1197,
      },
    ],
  },
  {
    ga: 191,
    values: [
      {
        centile: 3,
        value: 900,
      },
      {
        centile: 5,
        value: 917,
      },
      {
        centile: 10,
        value: 945,
      },
      {
        centile: 25,
        value: 992,
      },
      {
        centile: 50,
        value: 1049,
      },
      {
        centile: 75,
        value: 1108,
      },
      {
        centile: 90,
        value: 1164,
      },
      {
        centile: 95,
        value: 1199,
      },
      {
        centile: 97,
        value: 1222,
      },
    ],
  },
  {
    ga: 192,
    values: [
      {
        centile: 3,
        value: 918,
      },
      {
        centile: 5,
        value: 936,
      },
      {
        centile: 10,
        value: 964,
      },
      {
        centile: 25,
        value: 1013,
      },
      {
        centile: 50,
        value: 1070,
      },
      {
        centile: 75,
        value: 1131,
      },
      {
        centile: 90,
        value: 1188,
      },
      {
        centile: 95,
        value: 1224,
      },
      {
        centile: 97,
        value: 1248,
      },
    ],
  },
  {
    ga: 193,
    values: [
      {
        centile: 3,
        value: 936,
      },
      {
        centile: 5,
        value: 954,
      },
      {
        centile: 10,
        value: 983,
      },
      {
        centile: 25,
        value: 1033,
      },
      {
        centile: 50,
        value: 1092,
      },
      {
        centile: 75,
        value: 1154,
      },
      {
        centile: 90,
        value: 1212,
      },
      {
        centile: 95,
        value: 1249,
      },
      {
        centile: 97,
        value: 1273,
      },
    ],
  },
  {
    ga: 194,
    values: [
      {
        centile: 3,
        value: 955,
      },
      {
        centile: 5,
        value: 973,
      },
      {
        centile: 10,
        value: 1003,
      },
      {
        centile: 25,
        value: 1054,
      },
      {
        centile: 50,
        value: 1114,
      },
      {
        centile: 75,
        value: 1177,
      },
      {
        centile: 90,
        value: 1237,
      },
      {
        centile: 95,
        value: 1274,
      },
      {
        centile: 97,
        value: 1299,
      },
    ],
  },
  {
    ga: 195,
    values: [
      {
        centile: 3,
        value: 974,
      },
      {
        centile: 5,
        value: 993,
      },
      {
        centile: 10,
        value: 1023,
      },
      {
        centile: 25,
        value: 1075,
      },
      {
        centile: 50,
        value: 1136,
      },
      {
        centile: 75,
        value: 1201,
      },
      {
        centile: 90,
        value: 1262,
      },
      {
        centile: 95,
        value: 1300,
      },
      {
        centile: 97,
        value: 1326,
      },
    ],
  },
  {
    ga: 196,
    values: [
      {
        centile: 3,
        value: 993,
      },
      {
        centile: 5,
        value: 1012,
      },
      {
        centile: 10,
        value: 1043,
      },
      {
        centile: 25,
        value: 1096,
      },
      {
        centile: 50,
        value: 1159,
      },
      {
        centile: 75,
        value: 1225,
      },
      {
        centile: 90,
        value: 1287,
      },
      {
        centile: 95,
        value: 1326,
      },
      {
        centile: 97,
        value: 1352,
      },
    ],
  },
  {
    ga: 197,
    values: [
      {
        centile: 3,
        value: 1012,
      },
      {
        centile: 5,
        value: 1032,
      },
      {
        centile: 10,
        value: 1063,
      },
      {
        centile: 25,
        value: 1118,
      },
      {
        centile: 50,
        value: 1182,
      },
      {
        centile: 75,
        value: 1249,
      },
      {
        centile: 90,
        value: 1313,
      },
      {
        centile: 95,
        value: 1353,
      },
      {
        centile: 97,
        value: 1379,
      },
    ],
  },
  {
    ga: 198,
    values: [
      {
        centile: 3,
        value: 1032,
      },
      {
        centile: 5,
        value: 1052,
      },
      {
        centile: 10,
        value: 1084,
      },
      {
        centile: 25,
        value: 1140,
      },
      {
        centile: 50,
        value: 1205,
      },
      {
        centile: 75,
        value: 1274,
      },
      {
        centile: 90,
        value: 1339,
      },
      {
        centile: 95,
        value: 1380,
      },
      {
        centile: 97,
        value: 1407,
      },
    ],
  },
  {
    ga: 199,
    values: [
      {
        centile: 3,
        value: 1052,
      },
      {
        centile: 5,
        value: 1072,
      },
      {
        centile: 10,
        value: 1105,
      },
      {
        centile: 25,
        value: 1162,
      },
      {
        centile: 50,
        value: 1228,
      },
      {
        centile: 75,
        value: 1299,
      },
      {
        centile: 90,
        value: 1365,
      },
      {
        centile: 95,
        value: 1407,
      },
      {
        centile: 97,
        value: 1435,
      },
    ],
  },
  {
    ga: 200,
    values: [
      {
        centile: 3,
        value: 1072,
      },
      {
        centile: 5,
        value: 1093,
      },
      {
        centile: 10,
        value: 1126,
      },
      {
        centile: 25,
        value: 1184,
      },
      {
        centile: 50,
        value: 1252,
      },
      {
        centile: 75,
        value: 1324,
      },
      {
        centile: 90,
        value: 1392,
      },
      {
        centile: 95,
        value: 1434,
      },
      {
        centile: 97,
        value: 1463,
      },
    ],
  },
  {
    ga: 201,
    values: [
      {
        centile: 3,
        value: 1092,
      },
      {
        centile: 5,
        value: 1113,
      },
      {
        centile: 10,
        value: 1147,
      },
      {
        centile: 25,
        value: 1207,
      },
      {
        centile: 50,
        value: 1276,
      },
      {
        centile: 75,
        value: 1349,
      },
      {
        centile: 90,
        value: 1419,
      },
      {
        centile: 95,
        value: 1462,
      },
      {
        centile: 97,
        value: 1491,
      },
    ],
  },
  {
    ga: 202,
    values: [
      {
        centile: 3,
        value: 1112,
      },
      {
        centile: 5,
        value: 1134,
      },
      {
        centile: 10,
        value: 1169,
      },
      {
        centile: 25,
        value: 1230,
      },
      {
        centile: 50,
        value: 1300,
      },
      {
        centile: 75,
        value: 1375,
      },
      {
        centile: 90,
        value: 1446,
      },
      {
        centile: 95,
        value: 1490,
      },
      {
        centile: 97,
        value: 1520,
      },
    ],
  },
  {
    ga: 203,
    values: [
      {
        centile: 3,
        value: 1133,
      },
      {
        centile: 5,
        value: 1156,
      },
      {
        centile: 10,
        value: 1191,
      },
      {
        centile: 25,
        value: 1253,
      },
      {
        centile: 50,
        value: 1325,
      },
      {
        centile: 75,
        value: 1401,
      },
      {
        centile: 90,
        value: 1474,
      },
      {
        centile: 95,
        value: 1519,
      },
      {
        centile: 97,
        value: 1549,
      },
    ],
  },
  {
    ga: 204,
    values: [
      {
        centile: 3,
        value: 1154,
      },
      {
        centile: 5,
        value: 1177,
      },
      {
        centile: 10,
        value: 1213,
      },
      {
        centile: 25,
        value: 1276,
      },
      {
        centile: 50,
        value: 1350,
      },
      {
        centile: 75,
        value: 1428,
      },
      {
        centile: 90,
        value: 1502,
      },
      {
        centile: 95,
        value: 1548,
      },
      {
        centile: 97,
        value: 1578,
      },
    ],
  },
  {
    ga: 205,
    values: [
      {
        centile: 3,
        value: 1175,
      },
      {
        centile: 5,
        value: 1199,
      },
      {
        centile: 10,
        value: 1236,
      },
      {
        centile: 25,
        value: 1300,
      },
      {
        centile: 50,
        value: 1375,
      },
      {
        centile: 75,
        value: 1454,
      },
      {
        centile: 90,
        value: 1530,
      },
      {
        centile: 95,
        value: 1577,
      },
      {
        centile: 97,
        value: 1608,
      },
    ],
  },
  {
    ga: 206,
    values: [
      {
        centile: 3,
        value: 1197,
      },
      {
        centile: 5,
        value: 1221,
      },
      {
        centile: 10,
        value: 1258,
      },
      {
        centile: 25,
        value: 1324,
      },
      {
        centile: 50,
        value: 1400,
      },
      {
        centile: 75,
        value: 1481,
      },
      {
        centile: 90,
        value: 1558,
      },
      {
        centile: 95,
        value: 1606,
      },
      {
        centile: 97,
        value: 1638,
      },
    ],
  },
  {
    ga: 207,
    values: [
      {
        centile: 3,
        value: 1219,
      },
      {
        centile: 5,
        value: 1243,
      },
      {
        centile: 10,
        value: 1281,
      },
      {
        centile: 25,
        value: 1348,
      },
      {
        centile: 50,
        value: 1426,
      },
      {
        centile: 75,
        value: 1509,
      },
      {
        centile: 90,
        value: 1587,
      },
      {
        centile: 95,
        value: 1636,
      },
      {
        centile: 97,
        value: 1669,
      },
    ],
  },
  {
    ga: 208,
    values: [
      {
        centile: 3,
        value: 1240,
      },
      {
        centile: 5,
        value: 1265,
      },
      {
        centile: 10,
        value: 1304,
      },
      {
        centile: 25,
        value: 1372,
      },
      {
        centile: 50,
        value: 1452,
      },
      {
        centile: 75,
        value: 1536,
      },
      {
        centile: 90,
        value: 1616,
      },
      {
        centile: 95,
        value: 1666,
      },
      {
        centile: 97,
        value: 1700,
      },
    ],
  },
  {
    ga: 209,
    values: [
      {
        centile: 3,
        value: 1263,
      },
      {
        centile: 5,
        value: 1288,
      },
      {
        centile: 10,
        value: 1328,
      },
      {
        centile: 25,
        value: 1397,
      },
      {
        centile: 50,
        value: 1478,
      },
      {
        centile: 75,
        value: 1564,
      },
      {
        centile: 90,
        value: 1646,
      },
      {
        centile: 95,
        value: 1697,
      },
      {
        centile: 97,
        value: 1731,
      },
    ],
  },
  {
    ga: 210,
    values: [
      {
        centile: 3,
        value: 1285,
      },
      {
        centile: 5,
        value: 1311,
      },
      {
        centile: 10,
        value: 1351,
      },
      {
        centile: 25,
        value: 1422,
      },
      {
        centile: 50,
        value: 1505,
      },
      {
        centile: 75,
        value: 1592,
      },
      {
        centile: 90,
        value: 1676,
      },
      {
        centile: 95,
        value: 1728,
      },
      {
        centile: 97,
        value: 1762,
      },
    ],
  },
  {
    ga: 211,
    values: [
      {
        centile: 3,
        value: 1307,
      },
      {
        centile: 5,
        value: 1334,
      },
      {
        centile: 10,
        value: 1375,
      },
      {
        centile: 25,
        value: 1447,
      },
      {
        centile: 50,
        value: 1531,
      },
      {
        centile: 75,
        value: 1621,
      },
      {
        centile: 90,
        value: 1706,
      },
      {
        centile: 95,
        value: 1759,
      },
      {
        centile: 97,
        value: 1794,
      },
    ],
  },
  {
    ga: 212,
    values: [
      {
        centile: 3,
        value: 1330,
      },
      {
        centile: 5,
        value: 1357,
      },
      {
        centile: 10,
        value: 1399,
      },
      {
        centile: 25,
        value: 1472,
      },
      {
        centile: 50,
        value: 1558,
      },
      {
        centile: 75,
        value: 1649,
      },
      {
        centile: 90,
        value: 1736,
      },
      {
        centile: 95,
        value: 1790,
      },
      {
        centile: 97,
        value: 1826,
      },
    ],
  },
  {
    ga: 213,
    values: [
      {
        centile: 3,
        value: 1353,
      },
      {
        centile: 5,
        value: 1380,
      },
      {
        centile: 10,
        value: 1423,
      },
      {
        centile: 25,
        value: 1498,
      },
      {
        centile: 50,
        value: 1586,
      },
      {
        centile: 75,
        value: 1678,
      },
      {
        centile: 90,
        value: 1767,
      },
      {
        centile: 95,
        value: 1822,
      },
      {
        centile: 97,
        value: 1858,
      },
    ],
  },
  {
    ga: 214,
    values: [
      {
        centile: 3,
        value: 1376,
      },
      {
        centile: 5,
        value: 1404,
      },
      {
        centile: 10,
        value: 1447,
      },
      {
        centile: 25,
        value: 1524,
      },
      {
        centile: 50,
        value: 1613,
      },
      {
        centile: 75,
        value: 1708,
      },
      {
        centile: 90,
        value: 1798,
      },
      {
        centile: 95,
        value: 1854,
      },
      {
        centile: 97,
        value: 1891,
      },
    ],
  },
  {
    ga: 215,
    values: [
      {
        centile: 3,
        value: 1399,
      },
      {
        centile: 5,
        value: 1427,
      },
      {
        centile: 10,
        value: 1472,
      },
      {
        centile: 25,
        value: 1550,
      },
      {
        centile: 50,
        value: 1641,
      },
      {
        centile: 75,
        value: 1737,
      },
      {
        centile: 90,
        value: 1829,
      },
      {
        centile: 95,
        value: 1886,
      },
      {
        centile: 97,
        value: 1924,
      },
    ],
  },
  {
    ga: 216,
    values: [
      {
        centile: 3,
        value: 1423,
      },
      {
        centile: 5,
        value: 1451,
      },
      {
        centile: 10,
        value: 1497,
      },
      {
        centile: 25,
        value: 1576,
      },
      {
        centile: 50,
        value: 1669,
      },
      {
        centile: 75,
        value: 1767,
      },
      {
        centile: 90,
        value: 1860,
      },
      {
        centile: 95,
        value: 1918,
      },
      {
        centile: 97,
        value: 1957,
      },
    ],
  },
  {
    ga: 217,
    values: [
      {
        centile: 3,
        value: 1446,
      },
      {
        centile: 5,
        value: 1476,
      },
      {
        centile: 10,
        value: 1522,
      },
      {
        centile: 25,
        value: 1602,
      },
      {
        centile: 50,
        value: 1697,
      },
      {
        centile: 75,
        value: 1797,
      },
      {
        centile: 90,
        value: 1892,
      },
      {
        centile: 95,
        value: 1951,
      },
      {
        centile: 97,
        value: 1990,
      },
    ],
  },
  {
    ga: 218,
    values: [
      {
        centile: 3,
        value: 1470,
      },
      {
        centile: 5,
        value: 1500,
      },
      {
        centile: 10,
        value: 1547,
      },
      {
        centile: 25,
        value: 1629,
      },
      {
        centile: 50,
        value: 1725,
      },
      {
        centile: 75,
        value: 1827,
      },
      {
        centile: 90,
        value: 1924,
      },
      {
        centile: 95,
        value: 1984,
      },
      {
        centile: 97,
        value: 2024,
      },
    ],
  },
  {
    ga: 219,
    values: [
      {
        centile: 3,
        value: 1494,
      },
      {
        centile: 5,
        value: 1524,
      },
      {
        centile: 10,
        value: 1572,
      },
      {
        centile: 25,
        value: 1656,
      },
      {
        centile: 50,
        value: 1754,
      },
      {
        centile: 75,
        value: 1857,
      },
      {
        centile: 90,
        value: 1956,
      },
      {
        centile: 95,
        value: 2017,
      },
      {
        centile: 97,
        value: 2058,
      },
    ],
  },
  {
    ga: 220,
    values: [
      {
        centile: 3,
        value: 1518,
      },
      {
        centile: 5,
        value: 1549,
      },
      {
        centile: 10,
        value: 1598,
      },
      {
        centile: 25,
        value: 1683,
      },
      {
        centile: 50,
        value: 1782,
      },
      {
        centile: 75,
        value: 1888,
      },
      {
        centile: 90,
        value: 1988,
      },
      {
        centile: 95,
        value: 2051,
      },
      {
        centile: 97,
        value: 2092,
      },
    ],
  },
  {
    ga: 221,
    values: [
      {
        centile: 3,
        value: 1542,
      },
      {
        centile: 5,
        value: 1574,
      },
      {
        centile: 10,
        value: 1623,
      },
      {
        centile: 25,
        value: 1710,
      },
      {
        centile: 50,
        value: 1811,
      },
      {
        centile: 75,
        value: 1919,
      },
      {
        centile: 90,
        value: 2021,
      },
      {
        centile: 95,
        value: 2084,
      },
      {
        centile: 97,
        value: 2127,
      },
    ],
  },
  {
    ga: 222,
    values: [
      {
        centile: 3,
        value: 1567,
      },
      {
        centile: 5,
        value: 1599,
      },
      {
        centile: 10,
        value: 1649,
      },
      {
        centile: 25,
        value: 1737,
      },
      {
        centile: 50,
        value: 1840,
      },
      {
        centile: 75,
        value: 1950,
      },
      {
        centile: 90,
        value: 2054,
      },
      {
        centile: 95,
        value: 2118,
      },
      {
        centile: 97,
        value: 2162,
      },
    ],
  },
  {
    ga: 223,
    values: [
      {
        centile: 3,
        value: 1591,
      },
      {
        centile: 5,
        value: 1624,
      },
      {
        centile: 10,
        value: 1675,
      },
      {
        centile: 25,
        value: 1765,
      },
      {
        centile: 50,
        value: 1870,
      },
      {
        centile: 75,
        value: 1981,
      },
      {
        centile: 90,
        value: 2087,
      },
      {
        centile: 95,
        value: 2153,
      },
      {
        centile: 97,
        value: 2197,
      },
    ],
  },
  {
    ga: 224,
    values: [
      {
        centile: 3,
        value: 1616,
      },
      {
        centile: 5,
        value: 1649,
      },
      {
        centile: 10,
        value: 1701,
      },
      {
        centile: 25,
        value: 1792,
      },
      {
        centile: 50,
        value: 1899,
      },
      {
        centile: 75,
        value: 2012,
      },
      {
        centile: 90,
        value: 2120,
      },
      {
        centile: 95,
        value: 2187,
      },
      {
        centile: 97,
        value: 2232,
      },
    ],
  },
  {
    ga: 225,
    values: [
      {
        centile: 3,
        value: 1641,
      },
      {
        centile: 5,
        value: 1674,
      },
      {
        centile: 10,
        value: 1728,
      },
      {
        centile: 25,
        value: 1820,
      },
      {
        centile: 50,
        value: 1929,
      },
      {
        centile: 75,
        value: 2044,
      },
      {
        centile: 90,
        value: 2153,
      },
      {
        centile: 95,
        value: 2222,
      },
      {
        centile: 97,
        value: 2267,
      },
    ],
  },
  {
    ga: 226,
    values: [
      {
        centile: 3,
        value: 1666,
      },
      {
        centile: 5,
        value: 1700,
      },
      {
        centile: 10,
        value: 1754,
      },
      {
        centile: 25,
        value: 1848,
      },
      {
        centile: 50,
        value: 1958,
      },
      {
        centile: 75,
        value: 2075,
      },
      {
        centile: 90,
        value: 2187,
      },
      {
        centile: 95,
        value: 2256,
      },
      {
        centile: 97,
        value: 2303,
      },
    ],
  },
  {
    ga: 227,
    values: [
      {
        centile: 3,
        value: 1691,
      },
      {
        centile: 5,
        value: 1725,
      },
      {
        centile: 10,
        value: 1780,
      },
      {
        centile: 25,
        value: 1876,
      },
      {
        centile: 50,
        value: 1988,
      },
      {
        centile: 75,
        value: 2107,
      },
      {
        centile: 90,
        value: 2221,
      },
      {
        centile: 95,
        value: 2291,
      },
      {
        centile: 97,
        value: 2338,
      },
    ],
  },
  {
    ga: 228,
    values: [
      {
        centile: 3,
        value: 1716,
      },
      {
        centile: 5,
        value: 1751,
      },
      {
        centile: 10,
        value: 1807,
      },
      {
        centile: 25,
        value: 1904,
      },
      {
        centile: 50,
        value: 2018,
      },
      {
        centile: 75,
        value: 2139,
      },
      {
        centile: 90,
        value: 2254,
      },
      {
        centile: 95,
        value: 2326,
      },
      {
        centile: 97,
        value: 2374,
      },
    ],
  },
  {
    ga: 229,
    values: [
      {
        centile: 3,
        value: 1741,
      },
      {
        centile: 5,
        value: 1777,
      },
      {
        centile: 10,
        value: 1834,
      },
      {
        centile: 25,
        value: 1932,
      },
      {
        centile: 50,
        value: 2049,
      },
      {
        centile: 75,
        value: 2172,
      },
      {
        centile: 90,
        value: 2289,
      },
      {
        centile: 95,
        value: 2362,
      },
      {
        centile: 97,
        value: 2410,
      },
    ],
  },
  {
    ga: 230,
    values: [
      {
        centile: 3,
        value: 1766,
      },
      {
        centile: 5,
        value: 1803,
      },
      {
        centile: 10,
        value: 1860,
      },
      {
        centile: 25,
        value: 1961,
      },
      {
        centile: 50,
        value: 2079,
      },
      {
        centile: 75,
        value: 2204,
      },
      {
        centile: 90,
        value: 2323,
      },
      {
        centile: 95,
        value: 2397,
      },
      {
        centile: 97,
        value: 2446,
      },
    ],
  },
  {
    ga: 231,
    values: [
      {
        centile: 3,
        value: 1792,
      },
      {
        centile: 5,
        value: 1829,
      },
      {
        centile: 10,
        value: 1887,
      },
      {
        centile: 25,
        value: 1989,
      },
      {
        centile: 50,
        value: 2109,
      },
      {
        centile: 75,
        value: 2236,
      },
      {
        centile: 90,
        value: 2357,
      },
      {
        centile: 95,
        value: 2433,
      },
      {
        centile: 97,
        value: 2483,
      },
    ],
  },
  {
    ga: 232,
    values: [
      {
        centile: 3,
        value: 1817,
      },
      {
        centile: 5,
        value: 1855,
      },
      {
        centile: 10,
        value: 1914,
      },
      {
        centile: 25,
        value: 2018,
      },
      {
        centile: 50,
        value: 2140,
      },
      {
        centile: 75,
        value: 2269,
      },
      {
        centile: 90,
        value: 2392,
      },
      {
        centile: 95,
        value: 2468,
      },
      {
        centile: 97,
        value: 2519,
      },
    ],
  },
  {
    ga: 233,
    values: [
      {
        centile: 3,
        value: 1843,
      },
      {
        centile: 5,
        value: 1881,
      },
      {
        centile: 10,
        value: 1941,
      },
      {
        centile: 25,
        value: 2047,
      },
      {
        centile: 50,
        value: 2170,
      },
      {
        centile: 75,
        value: 2301,
      },
      {
        centile: 90,
        value: 2426,
      },
      {
        centile: 95,
        value: 2504,
      },
      {
        centile: 97,
        value: 2556,
      },
    ],
  },
  {
    ga: 234,
    values: [
      {
        centile: 3,
        value: 1868,
      },
      {
        centile: 5,
        value: 1907,
      },
      {
        centile: 10,
        value: 1968,
      },
      {
        centile: 25,
        value: 2075,
      },
      {
        centile: 50,
        value: 2201,
      },
      {
        centile: 75,
        value: 2334,
      },
      {
        centile: 90,
        value: 2461,
      },
      {
        centile: 95,
        value: 2540,
      },
      {
        centile: 97,
        value: 2593,
      },
    ],
  },
  {
    ga: 235,
    values: [
      {
        centile: 3,
        value: 1894,
      },
      {
        centile: 5,
        value: 1933,
      },
      {
        centile: 10,
        value: 1995,
      },
      {
        centile: 25,
        value: 2104,
      },
      {
        centile: 50,
        value: 2232,
      },
      {
        centile: 75,
        value: 2367,
      },
      {
        centile: 90,
        value: 2496,
      },
      {
        centile: 95,
        value: 2576,
      },
      {
        centile: 97,
        value: 2629,
      },
    ],
  },
  {
    ga: 236,
    values: [
      {
        centile: 3,
        value: 1919,
      },
      {
        centile: 5,
        value: 1959,
      },
      {
        centile: 10,
        value: 2023,
      },
      {
        centile: 25,
        value: 2133,
      },
      {
        centile: 50,
        value: 2262,
      },
      {
        centile: 75,
        value: 2400,
      },
      {
        centile: 90,
        value: 2530,
      },
      {
        centile: 95,
        value: 2612,
      },
      {
        centile: 97,
        value: 2666,
      },
    ],
  },
  {
    ga: 237,
    values: [
      {
        centile: 3,
        value: 1945,
      },
      {
        centile: 5,
        value: 1986,
      },
      {
        centile: 10,
        value: 2050,
      },
      {
        centile: 25,
        value: 2162,
      },
      {
        centile: 50,
        value: 2293,
      },
      {
        centile: 75,
        value: 2432,
      },
      {
        centile: 90,
        value: 2565,
      },
      {
        centile: 95,
        value: 2648,
      },
      {
        centile: 97,
        value: 2703,
      },
    ],
  },
  {
    ga: 238,
    values: [
      {
        centile: 3,
        value: 1971,
      },
      {
        centile: 5,
        value: 2012,
      },
      {
        centile: 10,
        value: 2077,
      },
      {
        centile: 25,
        value: 2191,
      },
      {
        centile: 50,
        value: 2324,
      },
      {
        centile: 75,
        value: 2465,
      },
      {
        centile: 90,
        value: 2600,
      },
      {
        centile: 95,
        value: 2684,
      },
      {
        centile: 97,
        value: 2740,
      },
    ],
  },
  {
    ga: 239,
    values: [
      {
        centile: 3,
        value: 1996,
      },
      {
        centile: 5,
        value: 2038,
      },
      {
        centile: 10,
        value: 2104,
      },
      {
        centile: 25,
        value: 2219,
      },
      {
        centile: 50,
        value: 2355,
      },
      {
        centile: 75,
        value: 2498,
      },
      {
        centile: 90,
        value: 2635,
      },
      {
        centile: 95,
        value: 2720,
      },
      {
        centile: 97,
        value: 2777,
      },
    ],
  },
  {
    ga: 240,
    values: [
      {
        centile: 3,
        value: 2022,
      },
      {
        centile: 5,
        value: 2064,
      },
      {
        centile: 10,
        value: 2131,
      },
      {
        centile: 25,
        value: 2248,
      },
      {
        centile: 50,
        value: 2386,
      },
      {
        centile: 75,
        value: 2531,
      },
      {
        centile: 90,
        value: 2670,
      },
      {
        centile: 95,
        value: 2757,
      },
      {
        centile: 97,
        value: 2814,
      },
    ],
  },
  {
    ga: 241,
    values: [
      {
        centile: 3,
        value: 2048,
      },
      {
        centile: 5,
        value: 2091,
      },
      {
        centile: 10,
        value: 2159,
      },
      {
        centile: 25,
        value: 2277,
      },
      {
        centile: 50,
        value: 2416,
      },
      {
        centile: 75,
        value: 2564,
      },
      {
        centile: 90,
        value: 2705,
      },
      {
        centile: 95,
        value: 2793,
      },
      {
        centile: 97,
        value: 2851,
      },
    ],
  },
  {
    ga: 242,
    values: [
      {
        centile: 3,
        value: 2073,
      },
      {
        centile: 5,
        value: 2117,
      },
      {
        centile: 10,
        value: 2186,
      },
      {
        centile: 25,
        value: 2306,
      },
      {
        centile: 50,
        value: 2447,
      },
      {
        centile: 75,
        value: 2597,
      },
      {
        centile: 90,
        value: 2740,
      },
      {
        centile: 95,
        value: 2829,
      },
      {
        centile: 97,
        value: 2888,
      },
    ],
  },
  {
    ga: 243,
    values: [
      {
        centile: 3,
        value: 2099,
      },
      {
        centile: 5,
        value: 2143,
      },
      {
        centile: 10,
        value: 2213,
      },
      {
        centile: 25,
        value: 2335,
      },
      {
        centile: 50,
        value: 2478,
      },
      {
        centile: 75,
        value: 2630,
      },
      {
        centile: 90,
        value: 2775,
      },
      {
        centile: 95,
        value: 2865,
      },
      {
        centile: 97,
        value: 2926,
      },
    ],
  },
  {
    ga: 244,
    values: [
      {
        centile: 3,
        value: 2124,
      },
      {
        centile: 5,
        value: 2169,
      },
      {
        centile: 10,
        value: 2240,
      },
      {
        centile: 25,
        value: 2363,
      },
      {
        centile: 50,
        value: 2509,
      },
      {
        centile: 75,
        value: 2663,
      },
      {
        centile: 90,
        value: 2810,
      },
      {
        centile: 95,
        value: 2901,
      },
      {
        centile: 97,
        value: 2963,
      },
    ],
  },
  {
    ga: 245,
    values: [
      {
        centile: 3,
        value: 2150,
      },
      {
        centile: 5,
        value: 2195,
      },
      {
        centile: 10,
        value: 2267,
      },
      {
        centile: 25,
        value: 2392,
      },
      {
        centile: 50,
        value: 2539,
      },
      {
        centile: 75,
        value: 2696,
      },
      {
        centile: 90,
        value: 2844,
      },
      {
        centile: 95,
        value: 2937,
      },
      {
        centile: 97,
        value: 2999,
      },
    ],
  },
  {
    ga: 246,
    values: [
      {
        centile: 3,
        value: 2175,
      },
      {
        centile: 5,
        value: 2221,
      },
      {
        centile: 10,
        value: 2294,
      },
      {
        centile: 25,
        value: 2421,
      },
      {
        centile: 50,
        value: 2570,
      },
      {
        centile: 75,
        value: 2728,
      },
      {
        centile: 90,
        value: 2879,
      },
      {
        centile: 95,
        value: 2973,
      },
      {
        centile: 97,
        value: 3036,
      },
    ],
  },
  {
    ga: 247,
    values: [
      {
        centile: 3,
        value: 2200,
      },
      {
        centile: 5,
        value: 2247,
      },
      {
        centile: 10,
        value: 2321,
      },
      {
        centile: 25,
        value: 2449,
      },
      {
        centile: 50,
        value: 2600,
      },
      {
        centile: 75,
        value: 2761,
      },
      {
        centile: 90,
        value: 2914,
      },
      {
        centile: 95,
        value: 3009,
      },
      {
        centile: 97,
        value: 3073,
      },
    ],
  },
  {
    ga: 248,
    values: [
      {
        centile: 3,
        value: 2226,
      },
      {
        centile: 5,
        value: 2273,
      },
      {
        centile: 10,
        value: 2347,
      },
      {
        centile: 25,
        value: 2478,
      },
      {
        centile: 50,
        value: 2631,
      },
      {
        centile: 75,
        value: 2793,
      },
      {
        centile: 90,
        value: 2948,
      },
      {
        centile: 95,
        value: 3045,
      },
      {
        centile: 97,
        value: 3110,
      },
    ],
  },
  {
    ga: 249,
    values: [
      {
        centile: 3,
        value: 2251,
      },
      {
        centile: 5,
        value: 2298,
      },
      {
        centile: 10,
        value: 2374,
      },
      {
        centile: 25,
        value: 2506,
      },
      {
        centile: 50,
        value: 2661,
      },
      {
        centile: 75,
        value: 2826,
      },
      {
        centile: 90,
        value: 2983,
      },
      {
        centile: 95,
        value: 3081,
      },
      {
        centile: 97,
        value: 3146,
      },
    ],
  },
  {
    ga: 250,
    values: [
      {
        centile: 3,
        value: 2276,
      },
      {
        centile: 5,
        value: 2324,
      },
      {
        centile: 10,
        value: 2401,
      },
      {
        centile: 25,
        value: 2534,
      },
      {
        centile: 50,
        value: 2691,
      },
      {
        centile: 75,
        value: 2858,
      },
      {
        centile: 90,
        value: 3017,
      },
      {
        centile: 95,
        value: 3116,
      },
      {
        centile: 97,
        value: 3183,
      },
    ],
  },
  {
    ga: 251,
    values: [
      {
        centile: 3,
        value: 2300,
      },
      {
        centile: 5,
        value: 2349,
      },
      {
        centile: 10,
        value: 2427,
      },
      {
        centile: 25,
        value: 2562,
      },
      {
        centile: 50,
        value: 2721,
      },
      {
        centile: 75,
        value: 2890,
      },
      {
        centile: 90,
        value: 3051,
      },
      {
        centile: 95,
        value: 3152,
      },
      {
        centile: 97,
        value: 3219,
      },
    ],
  },
  {
    ga: 252,
    values: [
      {
        centile: 3,
        value: 2325,
      },
      {
        centile: 5,
        value: 2375,
      },
      {
        centile: 10,
        value: 2453,
      },
      {
        centile: 25,
        value: 2590,
      },
      {
        centile: 50,
        value: 2751,
      },
      {
        centile: 75,
        value: 2922,
      },
      {
        centile: 90,
        value: 3085,
      },
      {
        centile: 95,
        value: 3187,
      },
      {
        centile: 97,
        value: 3255,
      },
    ],
  },
  {
    ga: 253,
    values: [
      {
        centile: 3,
        value: 2350,
      },
      {
        centile: 5,
        value: 2400,
      },
      {
        centile: 10,
        value: 2479,
      },
      {
        centile: 25,
        value: 2618,
      },
      {
        centile: 50,
        value: 2781,
      },
      {
        centile: 75,
        value: 2954,
      },
      {
        centile: 90,
        value: 3119,
      },
      {
        centile: 95,
        value: 3222,
      },
      {
        centile: 97,
        value: 3291,
      },
    ],
  },
  {
    ga: 254,
    values: [
      {
        centile: 3,
        value: 2374,
      },
      {
        centile: 5,
        value: 2425,
      },
      {
        centile: 10,
        value: 2505,
      },
      {
        centile: 25,
        value: 2645,
      },
      {
        centile: 50,
        value: 2810,
      },
      {
        centile: 75,
        value: 2985,
      },
      {
        centile: 90,
        value: 3153,
      },
      {
        centile: 95,
        value: 3257,
      },
      {
        centile: 97,
        value: 3327,
      },
    ],
  },
  {
    ga: 255,
    values: [
      {
        centile: 3,
        value: 2398,
      },
      {
        centile: 5,
        value: 2449,
      },
      {
        centile: 10,
        value: 2531,
      },
      {
        centile: 25,
        value: 2672,
      },
      {
        centile: 50,
        value: 2839,
      },
      {
        centile: 75,
        value: 3017,
      },
      {
        centile: 90,
        value: 3186,
      },
      {
        centile: 95,
        value: 3292,
      },
      {
        centile: 97,
        value: 3362,
      },
    ],
  },
  {
    ga: 256,
    values: [
      {
        centile: 3,
        value: 2422,
      },
      {
        centile: 5,
        value: 2474,
      },
      {
        centile: 10,
        value: 2556,
      },
      {
        centile: 25,
        value: 2700,
      },
      {
        centile: 50,
        value: 2868,
      },
      {
        centile: 75,
        value: 3048,
      },
      {
        centile: 90,
        value: 3219,
      },
      {
        centile: 95,
        value: 3326,
      },
      {
        centile: 97,
        value: 3397,
      },
    ],
  },
  {
    ga: 257,
    values: [
      {
        centile: 3,
        value: 2446,
      },
      {
        centile: 5,
        value: 2498,
      },
      {
        centile: 10,
        value: 2581,
      },
      {
        centile: 25,
        value: 2726,
      },
      {
        centile: 50,
        value: 2897,
      },
      {
        centile: 75,
        value: 3079,
      },
      {
        centile: 90,
        value: 3252,
      },
      {
        centile: 95,
        value: 3360,
      },
      {
        centile: 97,
        value: 3432,
      },
    ],
  },
  {
    ga: 258,
    values: [
      {
        centile: 3,
        value: 2469,
      },
      {
        centile: 5,
        value: 2522,
      },
      {
        centile: 10,
        value: 2606,
      },
      {
        centile: 25,
        value: 2753,
      },
      {
        centile: 50,
        value: 2926,
      },
      {
        centile: 75,
        value: 3109,
      },
      {
        centile: 90,
        value: 3284,
      },
      {
        centile: 95,
        value: 3394,
      },
      {
        centile: 97,
        value: 3467,
      },
    ],
  },
  {
    ga: 259,
    values: [
      {
        centile: 3,
        value: 2492,
      },
      {
        centile: 5,
        value: 2546,
      },
      {
        centile: 10,
        value: 2631,
      },
      {
        centile: 25,
        value: 2779,
      },
      {
        centile: 50,
        value: 2954,
      },
      {
        centile: 75,
        value: 3140,
      },
      {
        centile: 90,
        value: 3317,
      },
      {
        centile: 95,
        value: 3427,
      },
      {
        centile: 97,
        value: 3501,
      },
    ],
  },
  {
    ga: 260,
    values: [
      {
        centile: 3,
        value: 2515,
      },
      {
        centile: 5,
        value: 2570,
      },
      {
        centile: 10,
        value: 2656,
      },
      {
        centile: 25,
        value: 2806,
      },
      {
        centile: 50,
        value: 2982,
      },
      {
        centile: 75,
        value: 3170,
      },
      {
        centile: 90,
        value: 3349,
      },
      {
        centile: 95,
        value: 3461,
      },
      {
        centile: 97,
        value: 3535,
      },
    ],
  },
  {
    ga: 261,
    values: [
      {
        centile: 3,
        value: 2538,
      },
      {
        centile: 5,
        value: 2593,
      },
      {
        centile: 10,
        value: 2680,
      },
      {
        centile: 25,
        value: 2831,
      },
      {
        centile: 50,
        value: 3010,
      },
      {
        centile: 75,
        value: 3199,
      },
      {
        centile: 90,
        value: 3380,
      },
      {
        centile: 95,
        value: 3494,
      },
      {
        centile: 97,
        value: 3569,
      },
    ],
  },
  {
    ga: 262,
    values: [
      {
        centile: 3,
        value: 2561,
      },
      {
        centile: 5,
        value: 2616,
      },
      {
        centile: 10,
        value: 2704,
      },
      {
        centile: 25,
        value: 2857,
      },
      {
        centile: 50,
        value: 3037,
      },
      {
        centile: 75,
        value: 3229,
      },
      {
        centile: 90,
        value: 3412,
      },
      {
        centile: 95,
        value: 3526,
      },
      {
        centile: 97,
        value: 3602,
      },
    ],
  },
  {
    ga: 263,
    values: [
      {
        centile: 3,
        value: 2583,
      },
      {
        centile: 5,
        value: 2639,
      },
      {
        centile: 10,
        value: 2727,
      },
      {
        centile: 25,
        value: 2882,
      },
      {
        centile: 50,
        value: 3064,
      },
      {
        centile: 75,
        value: 3258,
      },
      {
        centile: 90,
        value: 3443,
      },
      {
        centile: 95,
        value: 3558,
      },
      {
        centile: 97,
        value: 3635,
      },
    ],
  },
  {
    ga: 264,
    values: [
      {
        centile: 3,
        value: 2605,
      },
      {
        centile: 5,
        value: 2661,
      },
      {
        centile: 10,
        value: 2751,
      },
      {
        centile: 25,
        value: 2907,
      },
      {
        centile: 50,
        value: 3091,
      },
      {
        centile: 75,
        value: 3287,
      },
      {
        centile: 90,
        value: 3473,
      },
      {
        centile: 95,
        value: 3590,
      },
      {
        centile: 97,
        value: 3668,
      },
    ],
  },
  {
    ga: 265,
    values: [
      {
        centile: 3,
        value: 2626,
      },
      {
        centile: 5,
        value: 2683,
      },
      {
        centile: 10,
        value: 2774,
      },
      {
        centile: 25,
        value: 2931,
      },
      {
        centile: 50,
        value: 3117,
      },
      {
        centile: 75,
        value: 3315,
      },
      {
        centile: 90,
        value: 3503,
      },
      {
        centile: 95,
        value: 3621,
      },
      {
        centile: 97,
        value: 3700,
      },
    ],
  },
  {
    ga: 266,
    values: [
      {
        centile: 3,
        value: 2647,
      },
      {
        centile: 5,
        value: 2705,
      },
      {
        centile: 10,
        value: 2796,
      },
      {
        centile: 25,
        value: 2956,
      },
      {
        centile: 50,
        value: 3143,
      },
      {
        centile: 75,
        value: 3343,
      },
      {
        centile: 90,
        value: 3533,
      },
      {
        centile: 95,
        value: 3652,
      },
      {
        centile: 97,
        value: 3732,
      },
    ],
  },
  {
    ga: 267,
    values: [
      {
        centile: 3,
        value: 2668,
      },
      {
        centile: 5,
        value: 2726,
      },
      {
        centile: 10,
        value: 2819,
      },
      {
        centile: 25,
        value: 2979,
      },
      {
        centile: 50,
        value: 3169,
      },
      {
        centile: 75,
        value: 3370,
      },
      {
        centile: 90,
        value: 3563,
      },
      {
        centile: 95,
        value: 3683,
      },
      {
        centile: 97,
        value: 3763,
      },
    ],
  },
  {
    ga: 268,
    values: [
      {
        centile: 3,
        value: 2689,
      },
      {
        centile: 5,
        value: 2748,
      },
      {
        centile: 10,
        value: 2840,
      },
      {
        centile: 25,
        value: 3003,
      },
      {
        centile: 50,
        value: 3194,
      },
      {
        centile: 75,
        value: 3397,
      },
      {
        centile: 90,
        value: 3591,
      },
      {
        centile: 95,
        value: 3713,
      },
      {
        centile: 97,
        value: 3794,
      },
    ],
  },
  {
    ga: 269,
    values: [
      {
        centile: 3,
        value: 2709,
      },
      {
        centile: 5,
        value: 2768,
      },
      {
        centile: 10,
        value: 2862,
      },
      {
        centile: 25,
        value: 3026,
      },
      {
        centile: 50,
        value: 3219,
      },
      {
        centile: 75,
        value: 3424,
      },
      {
        centile: 90,
        value: 3620,
      },
      {
        centile: 95,
        value: 3742,
      },
      {
        centile: 97,
        value: 3824,
      },
    ],
  },
  {
    ga: 270,
    values: [
      {
        centile: 3,
        value: 2729,
      },
      {
        centile: 5,
        value: 2789,
      },
      {
        centile: 10,
        value: 2883,
      },
      {
        centile: 25,
        value: 3048,
      },
      {
        centile: 50,
        value: 3243,
      },
      {
        centile: 75,
        value: 3450,
      },
      {
        centile: 90,
        value: 3648,
      },
      {
        centile: 95,
        value: 3771,
      },
      {
        centile: 97,
        value: 3854,
      },
    ],
  },
  {
    ga: 271,
    values: [
      {
        centile: 3,
        value: 2748,
      },
      {
        centile: 5,
        value: 2808,
      },
      {
        centile: 10,
        value: 2904,
      },
      {
        centile: 25,
        value: 3070,
      },
      {
        centile: 50,
        value: 3267,
      },
      {
        centile: 75,
        value: 3476,
      },
      {
        centile: 90,
        value: 3675,
      },
      {
        centile: 95,
        value: 3800,
      },
      {
        centile: 97,
        value: 3883,
      },
    ],
  },
  {
    ga: 272,
    values: [
      {
        centile: 3,
        value: 2767,
      },
      {
        centile: 5,
        value: 2828,
      },
      {
        centile: 10,
        value: 2924,
      },
      {
        centile: 25,
        value: 3092,
      },
      {
        centile: 50,
        value: 3290,
      },
      {
        centile: 75,
        value: 3501,
      },
      {
        centile: 90,
        value: 3702,
      },
      {
        centile: 95,
        value: 3828,
      },
      {
        centile: 97,
        value: 3912,
      },
    ],
  },
  {
    ga: 273,
    values: [
      {
        centile: 3,
        value: 2786,
      },
      {
        centile: 5,
        value: 2847,
      },
      {
        centile: 10,
        value: 2944,
      },
      {
        centile: 25,
        value: 3113,
      },
      {
        centile: 50,
        value: 3313,
      },
      {
        centile: 75,
        value: 3526,
      },
      {
        centile: 90,
        value: 3728,
      },
      {
        centile: 95,
        value: 3855,
      },
      {
        centile: 97,
        value: 3940,
      },
    ],
  },
  {
    ga: 274,
    values: [
      {
        centile: 3,
        value: 2804,
      },
      {
        centile: 5,
        value: 2866,
      },
      {
        centile: 10,
        value: 2963,
      },
      {
        centile: 25,
        value: 3134,
      },
      {
        centile: 50,
        value: 3335,
      },
      {
        centile: 75,
        value: 3550,
      },
      {
        centile: 90,
        value: 3754,
      },
      {
        centile: 95,
        value: 3882,
      },
      {
        centile: 97,
        value: 3968,
      },
    ],
  },
  {
    ga: 275,
    values: [
      {
        centile: 3,
        value: 2821,
      },
      {
        centile: 5,
        value: 2884,
      },
      {
        centile: 10,
        value: 2982,
      },
      {
        centile: 25,
        value: 3154,
      },
      {
        centile: 50,
        value: 3357,
      },
      {
        centile: 75,
        value: 3573,
      },
      {
        centile: 90,
        value: 3779,
      },
      {
        centile: 95,
        value: 3909,
      },
      {
        centile: 97,
        value: 3995,
      },
    ],
  },
  {
    ga: 276,
    values: [
      {
        centile: 3,
        value: 2839,
      },
      {
        centile: 5,
        value: 2901,
      },
      {
        centile: 10,
        value: 3001,
      },
      {
        centile: 25,
        value: 3174,
      },
      {
        centile: 50,
        value: 3379,
      },
      {
        centile: 75,
        value: 3596,
      },
      {
        centile: 90,
        value: 3804,
      },
      {
        centile: 95,
        value: 3934,
      },
      {
        centile: 97,
        value: 4021,
      },
    ],
  },
  {
    ga: 277,
    values: [
      {
        centile: 3,
        value: 2855,
      },
      {
        centile: 5,
        value: 2919,
      },
      {
        centile: 10,
        value: 3019,
      },
      {
        centile: 25,
        value: 3193,
      },
      {
        centile: 50,
        value: 3399,
      },
      {
        centile: 75,
        value: 3619,
      },
      {
        centile: 90,
        value: 3828,
      },
      {
        centile: 95,
        value: 3959,
      },
      {
        centile: 97,
        value: 4047,
      },
    ],
  },
  {
    ga: 278,
    values: [
      {
        centile: 3,
        value: 2872,
      },
      {
        centile: 5,
        value: 2935,
      },
      {
        centile: 10,
        value: 3036,
      },
      {
        centile: 25,
        value: 3212,
      },
      {
        centile: 50,
        value: 3420,
      },
      {
        centile: 75,
        value: 3640,
      },
      {
        centile: 90,
        value: 3852,
      },
      {
        centile: 95,
        value: 3984,
      },
      {
        centile: 97,
        value: 4072,
      },
    ],
  },
  {
    ga: 279,
    values: [
      {
        centile: 3,
        value: 2888,
      },
      {
        centile: 5,
        value: 2952,
      },
      {
        centile: 10,
        value: 3053,
      },
      {
        centile: 25,
        value: 3230,
      },
      {
        centile: 50,
        value: 3439,
      },
      {
        centile: 75,
        value: 3662,
      },
      {
        centile: 90,
        value: 3874,
      },
      {
        centile: 95,
        value: 4007,
      },
      {
        centile: 97,
        value: 4096,
      },
    ],
  },
  {
    ga: 280,
    values: [
      {
        centile: 3,
        value: 2903,
      },
      {
        centile: 5,
        value: 2967,
      },
      {
        centile: 10,
        value: 3069,
      },
      {
        centile: 25,
        value: 3248,
      },
      {
        centile: 50,
        value: 3458,
      },
      {
        centile: 75,
        value: 3682,
      },
      {
        centile: 90,
        value: 3896,
      },
      {
        centile: 95,
        value: 4030,
      },
      {
        centile: 97,
        value: 4120,
      },
    ],
  },
  {
    ga: 281,
    values: [
      {
        centile: 3,
        value: 2918,
      },
      {
        centile: 5,
        value: 2982,
      },
      {
        centile: 10,
        value: 3085,
      },
      {
        centile: 25,
        value: 3265,
      },
      {
        centile: 50,
        value: 3477,
      },
      {
        centile: 75,
        value: 3702,
      },
      {
        centile: 90,
        value: 3918,
      },
      {
        centile: 95,
        value: 4053,
      },
      {
        centile: 97,
        value: 4143,
      },
    ],
  },
  {
    ga: 282,
    values: [
      {
        centile: 3,
        value: 2932,
      },
      {
        centile: 5,
        value: 2997,
      },
      {
        centile: 10,
        value: 3100,
      },
      {
        centile: 25,
        value: 3281,
      },
      {
        centile: 50,
        value: 3494,
      },
      {
        centile: 75,
        value: 3722,
      },
      {
        centile: 90,
        value: 3939,
      },
      {
        centile: 95,
        value: 4074,
      },
      {
        centile: 97,
        value: 4165,
      },
    ],
  },
  {
    ga: 283,
    values: [
      {
        centile: 3,
        value: 2945,
      },
      {
        centile: 5,
        value: 3011,
      },
      {
        centile: 10,
        value: 3115,
      },
      {
        centile: 25,
        value: 3297,
      },
      {
        centile: 50,
        value: 3512,
      },
      {
        centile: 75,
        value: 3740,
      },
      {
        centile: 90,
        value: 3959,
      },
      {
        centile: 95,
        value: 4095,
      },
      {
        centile: 97,
        value: 4187,
      },
    ],
  },
  {
    ga: 284,
    values: [
      {
        centile: 3,
        value: 2959,
      },
      {
        centile: 5,
        value: 3025,
      },
      {
        centile: 10,
        value: 3129,
      },
      {
        centile: 25,
        value: 3312,
      },
      {
        centile: 50,
        value: 3528,
      },
      {
        centile: 75,
        value: 3758,
      },
      {
        centile: 90,
        value: 3978,
      },
      {
        centile: 95,
        value: 4115,
      },
      {
        centile: 97,
        value: 4207,
      },
    ],
  },
  {
    ga: 285,
    values: [
      {
        centile: 3,
        value: 2971,
      },
      {
        centile: 5,
        value: 3038,
      },
      {
        centile: 10,
        value: 3143,
      },
      {
        centile: 25,
        value: 3327,
      },
      {
        centile: 50,
        value: 3544,
      },
      {
        centile: 75,
        value: 3775,
      },
      {
        centile: 90,
        value: 3996,
      },
      {
        centile: 95,
        value: 4135,
      },
      {
        centile: 97,
        value: 4227,
      },
    ],
  },
  {
    ga: 286,
    values: [
      {
        centile: 3,
        value: 2983,
      },
      {
        centile: 5,
        value: 3050,
      },
      {
        centile: 10,
        value: 3156,
      },
      {
        centile: 25,
        value: 3341,
      },
      {
        centile: 50,
        value: 3559,
      },
      {
        centile: 75,
        value: 3792,
      },
      {
        centile: 90,
        value: 4014,
      },
      {
        centile: 95,
        value: 4153,
      },
      {
        centile: 97,
        value: 4246,
      },
    ],
  },
  {
    ga: 287,
    values: [
      {
        centile: 3,
        value: 2995,
      },
      {
        centile: 5,
        value: 3062,
      },
      {
        centile: 10,
        value: 3168,
      },
      {
        centile: 25,
        value: 3354,
      },
      {
        centile: 50,
        value: 3574,
      },
      {
        centile: 75,
        value: 3808,
      },
      {
        centile: 90,
        value: 4031,
      },
      {
        centile: 95,
        value: 4171,
      },
      {
        centile: 97,
        value: 4265,
      },
    ],
  },
  {
    ga: 288,
    values: [
      {
        centile: 3,
        value: 3006,
      },
      {
        centile: 5,
        value: 3073,
      },
      {
        centile: 10,
        value: 3180,
      },
      {
        centile: 25,
        value: 3367,
      },
      {
        centile: 50,
        value: 3588,
      },
      {
        centile: 75,
        value: 3823,
      },
      {
        centile: 90,
        value: 4048,
      },
      {
        centile: 95,
        value: 4188,
      },
      {
        centile: 97,
        value: 4282,
      },
    ],
  },
  {
    ga: 289,
    values: [
      {
        centile: 3,
        value: 3016,
      },
      {
        centile: 5,
        value: 3084,
      },
      {
        centile: 10,
        value: 3191,
      },
      {
        centile: 25,
        value: 3379,
      },
      {
        centile: 50,
        value: 3601,
      },
      {
        centile: 75,
        value: 3837,
      },
      {
        centile: 90,
        value: 4063,
      },
      {
        centile: 95,
        value: 4205,
      },
      {
        centile: 97,
        value: 4299,
      },
    ],
  },
  {
    ga: 290,
    values: [
      {
        centile: 3,
        value: 3025,
      },
      {
        centile: 5,
        value: 3094,
      },
      {
        centile: 10,
        value: 3201,
      },
      {
        centile: 25,
        value: 3390,
      },
      {
        centile: 50,
        value: 3613,
      },
      {
        centile: 75,
        value: 3851,
      },
      {
        centile: 90,
        value: 4078,
      },
      {
        centile: 95,
        value: 4220,
      },
      {
        centile: 97,
        value: 4315,
      },
    ],
  },
  {
    ga: 291,
    values: [
      {
        centile: 3,
        value: 3034,
      },
      {
        centile: 5,
        value: 3103,
      },
      {
        centile: 10,
        value: 3211,
      },
      {
        centile: 25,
        value: 3401,
      },
      {
        centile: 50,
        value: 3625,
      },
      {
        centile: 75,
        value: 3863,
      },
      {
        centile: 90,
        value: 4092,
      },
      {
        centile: 95,
        value: 4234,
      },
      {
        centile: 97,
        value: 4330,
      },
    ],
  },
  {
    ga: 292,
    values: [
      {
        centile: 3,
        value: 3043,
      },
      {
        centile: 5,
        value: 3112,
      },
      {
        centile: 10,
        value: 3220,
      },
      {
        centile: 25,
        value: 3411,
      },
      {
        centile: 50,
        value: 3636,
      },
      {
        centile: 75,
        value: 3875,
      },
      {
        centile: 90,
        value: 4105,
      },
      {
        centile: 95,
        value: 4248,
      },
      {
        centile: 97,
        value: 4344,
      },
    ],
  },
  {
    ga: 293,
    values: [
      {
        centile: 3,
        value: 3051,
      },
      {
        centile: 5,
        value: 3120,
      },
      {
        centile: 10,
        value: 3229,
      },
      {
        centile: 25,
        value: 3420,
      },
      {
        centile: 50,
        value: 3646,
      },
      {
        centile: 75,
        value: 3887,
      },
      {
        centile: 90,
        value: 4117,
      },
      {
        centile: 95,
        value: 4261,
      },
      {
        centile: 97,
        value: 4357,
      },
    ],
  },
  {
    ga: 294,
    values: [
      {
        centile: 3,
        value: 3058,
      },
      {
        centile: 5,
        value: 3127,
      },
      {
        centile: 10,
        value: 3237,
      },
      {
        centile: 25,
        value: 3429,
      },
      {
        centile: 50,
        value: 3655,
      },
      {
        centile: 75,
        value: 3897,
      },
      {
        centile: 90,
        value: 4128,
      },
      {
        centile: 95,
        value: 4273,
      },
      {
        centile: 97,
        value: 4370,
      },
    ],
  },
  {
    ga: 295,
    values: [
      {
        centile: 3,
        value: 3064,
      },
      {
        centile: 5,
        value: 3134,
      },
      {
        centile: 10,
        value: 3244,
      },
      {
        centile: 25,
        value: 3437,
      },
      {
        centile: 50,
        value: 3664,
      },
      {
        centile: 75,
        value: 3907,
      },
      {
        centile: 90,
        value: 4139,
      },
      {
        centile: 95,
        value: 4284,
      },
      {
        centile: 97,
        value: 4381,
      },
    ],
  },
  {
    ga: 296,
    values: [
      {
        centile: 3,
        value: 3070,
      },
      {
        centile: 5,
        value: 3140,
      },
      {
        centile: 10,
        value: 3250,
      },
      {
        centile: 25,
        value: 3444,
      },
      {
        centile: 50,
        value: 3672,
      },
      {
        centile: 75,
        value: 3915,
      },
      {
        centile: 90,
        value: 4148,
      },
      {
        centile: 95,
        value: 4294,
      },
      {
        centile: 97,
        value: 4392,
      },
    ],
  },
  {
    ga: 297,
    values: [
      {
        centile: 3,
        value: 3075,
      },
      {
        centile: 5,
        value: 3145,
      },
      {
        centile: 10,
        value: 3256,
      },
      {
        centile: 25,
        value: 3450,
      },
      {
        centile: 50,
        value: 3679,
      },
      {
        centile: 75,
        value: 3923,
      },
      {
        centile: 90,
        value: 4157,
      },
      {
        centile: 95,
        value: 4303,
      },
      {
        centile: 97,
        value: 4401,
      },
    ],
  },
  {
    ga: 298,
    values: [
      {
        centile: 3,
        value: 3080,
      },
      {
        centile: 5,
        value: 3150,
      },
      {
        centile: 10,
        value: 3261,
      },
      {
        centile: 25,
        value: 3456,
      },
      {
        centile: 50,
        value: 3685,
      },
      {
        centile: 75,
        value: 3930,
      },
      {
        centile: 90,
        value: 4165,
      },
      {
        centile: 95,
        value: 4312,
      },
      {
        centile: 97,
        value: 4410,
      },
    ],
  },
  {
    ga: 299,
    values: [
      {
        centile: 3,
        value: 3084,
      },
      {
        centile: 5,
        value: 3154,
      },
      {
        centile: 10,
        value: 3265,
      },
      {
        centile: 25,
        value: 3460,
      },
      {
        centile: 50,
        value: 3691,
      },
      {
        centile: 75,
        value: 3937,
      },
      {
        centile: 90,
        value: 4172,
      },
      {
        centile: 95,
        value: 4319,
      },
      {
        centile: 97,
        value: 4417,
      },
    ],
  },
  {
    ga: 300,
    values: [
      {
        centile: 3,
        value: 3087,
      },
      {
        centile: 5,
        value: 3157,
      },
      {
        centile: 10,
        value: 3269,
      },
      {
        centile: 25,
        value: 3465,
      },
      {
        centile: 50,
        value: 3696,
      },
      {
        centile: 75,
        value: 3942,
      },
      {
        centile: 90,
        value: 4178,
      },
      {
        centile: 95,
        value: 4325,
      },
      {
        centile: 97,
        value: 4424,
      },
    ],
  },
];
