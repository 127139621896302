import { useEffect, useState } from "react";

import {
  Group,
  // Image,
  Menu,
  // UnstyledButton,
  Button,
  // rem,
  // useMantineColorScheme,
  // ActionIcon,
  type MenuProps,
  Text,
} from "@mantine/core";

import { IconChevronDown } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { LOCAL_STORAGE_LANG_KEY } from "@/utils";
// import useStyles from "./LanguagePicker.styles";

interface LangItem {
  label: string;
  image: string;
  lang: string;
}

const data: LangItem[] = [
  {
    label: "English",
    image: "uk-flag.svg",
    lang: "en",
  },
  {
    label: "Español",
    image: "/spanish-flag.svg",
    lang: "es",
  },
  {
    label: "Português",
    image: "/spanish-flag.svg",
    lang: "pt",
  },
];

type LanguagePickerProps = {
  type: "collapsed" | "expanded";
  size?: number;
} & MenuProps;

export const LanguagePicker = ({ type, size = 30 }: LanguagePickerProps) => {
  const { i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  // const { colorScheme } = useMantineColorScheme();
  // const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(
    data.find((c) => c.lang === i18n.language)
  );
  useEffect(() => {
    setSelected(data.find((c) => i18n.language?.includes(c.lang)));
  }, [i18n.language]);

  const handleLangChange = (item: LangItem) => {
    void i18n.changeLanguage(item.lang);
    setSelected(item);
    window?.localStorage.setItem(LOCAL_STORAGE_LANG_KEY, item.lang);
  };

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="sm"
    >
      <Menu.Target>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 8,
            transition: "background-color 150ms ease",
          }}
        >
          {type === "expanded" && (
            <IconChevronDown
              size="1rem"
              stroke={1.5}
              style={{
                marginRight: 4,
                transition: "transform 150ms ease",
                transform: opened ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          )}
          <Group gap="xs">
            {/* <ActionIcon
              variant="transparent"
              size={size}
              radius="xl"
              aria-label="Settings"
            >
              <Image
                src={selected?.image}
                width="100%"
                height="100%"
                alt="flag"
                radius={size}
              />
            </ActionIcon> */}
            <Button
              variant="outline"
              size="compact-sm"
              styles={{ section: { margin: 0 } }}
              rightSection={
                <IconChevronDown
                  size="1rem"
                  stroke={1.5}
                  style={{
                    transition: "transform 100ms ease",
                    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              {selected?.lang.toLocaleUpperCase()}
            </Button>
            {type === "expanded" && <span>{selected?.label}</span>}
          </Group>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {data.map((item) => (
          <Menu.Item onClick={() => handleLangChange(item)} key={item.label}>
            <Group>
              {/* <Image
                src={item?.image}
                width={size}
                height={size}
                alt="flag"
                radius={size}
                styles={{
                  root: {
                    maxWidth: 20,
                    maxHeight: 20,
                  },
                }}
              /> */}
              <Text> {item.label}</Text>
            </Group>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
