import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import {
  Flex,
  Card,
  Box,
  Text,
  Button,
  Divider,
  ActionIcon,
  Fieldset,
  Grid,
  Alert,
  NumberInput,
  Tooltip,
  Group,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { type UnitNum } from "@/types";
import { UnitNumInput } from "@/components/form/UnitNumInput";
import { DatePickerInput } from "@mantine/dates";
import {
  IconRefresh,
  IconMathLower,
  IconMathGreater,
  IconAlertCircle,
  IconArrowBack,
} from "@tabler/icons-react";
import { fmf } from "@alma/fmf";
import { un } from "@/utils";
import { notifications } from "@mantine/notifications";
import Link from "next/link";
interface State {
  ecoAge: number | null;
  ipAutIz?: number | null;
  ipAutDer?: number | null;
  ipAU?: number | null;
  ipDV?: number | null;
  ipACM?: number | null;
  crl?: UnitNum | null;
  hc?: UnitNum | null;
  ac?: UnitNum | null;
  fl?: UnitNum | null;
  efw?: UnitNum | null;
  fumc?: string | null;
  gaFromHc?: boolean;
  fechaEco?: string | null;
  showResultsPage?: boolean;
}

const initialValues = {
  ecoAge: null,
  ipAutIz: null,
  ipAutDer: null,
  ipAU: null,
  ipACM: null,
  ipDV: null,
  crl: null,
  hc: null,
  ac: null,
  fl: null,
  efw: null,
  fechaEco: null,
  fumc: null,
  gaFromHc: false,
  showResultsPage: false,
};

export function Calc() {
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>(initialValues);

  const update = (obj: Partial<State>) => {
    setState((prev) => ({ ...prev, ...obj }));
  };

  useEffect(() => {
    const hc = un(state.hc)?.to("mm").toNumber();
    const ac = un(state.ac)?.to("mm").toNumber();
    const fl = un(state.fl)?.to("mm").toNumber();
    const weight = fmf.calculateEFWFromBiometry({ hc, ac, fl }) as
      | number
      | null
      | undefined;

    if (weight) {
      update({ efw: { value: weight, unit: "g" } });
    } else {
      update({ efw: null });
    }
  }, [state.hc, state.ac, state.fl]);

  const ga = useMemo(() => {
    const crl = state?.crl;
    const hc = state?.hc;
    const fumc = state?.fumc;
    const ecoDate = state?.fechaEco && new Date(state.fechaEco);

    const gaFromCRL = fmf.gaFromCrl(un(crl)?.to("mm").toNumber() ?? 0, 1);
    const gaFromFUMc = fumc && ecoDate ? fmf.gaFromLmp(fumc, ecoDate) : null;
    const gaFromHC = fmf.gaFromHc(un(hc)?.to("mm").toNumber() ?? 0);

    if (gaFromCRL) {
      update({ gaFromHc: false });
      // console.log("ga from CRL");
      return !isNaN(gaFromCRL) && gaFromCRL > 0 ? gaFromCRL : null;
    }

    if (gaFromFUMc && state?.ecoAge && state.ecoAge > 14) {
      if (!state.gaFromHc) {
        update({ gaFromHc: false });
      }
      if (!state.gaFromHc) {
        // console.log("ga from FUMc");
        return !isNaN(gaFromFUMc) && gaFromFUMc > 0 ? gaFromFUMc : null;
      }
    }

    if (gaFromHC) {
      console.log("ga from HC");
      update({ gaFromHc: true });
      return !isNaN(gaFromHC) && gaFromHC > 0 ? gaFromHC : null;
    }
    // console.log("ga from null");
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.crl, state?.hc, state?.fumc, state?.fechaEco]);

  const gaObject = ga ? fmf.daysToOWObject(ga) : null;

  const relCerPlacentaria = fmf.calcCPR(state?.ipACM, state?.ipAU);

  useEffect(() => {
    if (!!state?.ecoAge) {
      if (state.ecoAge > 14) {
        if (gaObject && state?.fechaEco) {
          const result = dayjs
            .utc(state?.fechaEco)
            .subtract(gaObject.weeks, "weeks")
            .subtract(gaObject.days ?? 0, "days");
          update({ fumc: dayjs.utc(result).toString() });
        }
        if (state.gaFromHc) {
          if (state?.fumc && !state?.hc) {
            update({ fumc: null });
          } else if (!ga) {
            update({ fumc: null });
          }
        }
      } else if (state.ecoAge < 14) {
        if (ga && gaObject && state?.fechaEco) {
          const result = dayjs
            .utc(state?.fechaEco)
            .subtract(gaObject.weeks, "weeks")
            .subtract(gaObject.days ?? 0, "days");
          update({ fumc: dayjs.utc(result).toString() });
        } else if (!ga || !state?.fechaEco) {
          update({ fumc: null });
        }
      }
    }
  }, [state?.hc, state?.fechaEco, state?.crl, ga]);

  const gaToday = useMemo(() => {
    if (ga && state?.fechaEco) {
      const ecoDate = dayjs.utc(state.fechaEco);
      const today = dayjs.utc();

      const diffDays = ecoDate.diff(today, "days");

      return fmf.daysToOW(ga + Math.abs(diffDays));
    }
    return null;
  }, [ga, state?.fechaEco]);

  useEffect(() => {
    if (state.ecoAge && state?.gaFromHc) {
      notifications.show({
        color: "yellow",
        message: t("gaCalcFromHCWarningMessage"),
      });
    }
  }, [state?.ecoAge, state?.gaFromHc]);

  const efwP = fmf.centileFromFW(state?.efw?.value, ga);
  const ipAutP =
    state?.ipAutIz && state?.ipAutDer
      ? fmf.uterineArteryPICentile(
          ((state?.ipAutIz ?? 0) + (state?.ipAutDer ?? 0)) / 2,
          Math.round(ga ?? 0) ?? null
        )
      : null;

  const ipAUP = fmf.umbilicalArteryPICentile(state.ipAU, ga ?? 0);
  const ipACMP = fmf.middleCerebralPICentile(state.ipACM, ga ?? 0);
  const ipDVP = fmf.doctusVenosusPICentile(state.ipDV, ga ?? 0);
  const cerebroPlacentalRatioCentile = fmf.cerebroPlacentalRatioCentile(
    relCerPlacentaria,
    ga
  );
  return (
    <Box c="#fafafa">
      <Flex justify="center" align="flex-start" gap="sm" wrap="wrap">
        <Card
          shadow="md"
          p={{ base: "xs", md: "md" }}
          radius="md"
          withBorder
          maw={state?.ecoAge && state.ecoAge < 14 ? 360 : 900}
        >
          <Flex justify="space-between">
            <Text fw={500}>
              {!state?.ecoAge
                ? t("whatUltrasoundDoYouWantToCalc")
                : state.ecoAge > 14
                  ? t("ultrasoundGT14Weeks")
                  : t("ultrasoundLT14Weeks")}
            </Text>
            <Group gap={4} wrap="nowrap">
              {state.ecoAge && (
                <Tooltip label={t("back")}>
                  <ActionIcon
                    variant="transparent"
                    onClick={() => update(initialValues)}
                  >
                    <IconArrowBack />
                  </ActionIcon>
                </Tooltip>
              )}
              {state.ecoAge && (
                <Tooltip label={t("clearValues")}>
                  <ActionIcon
                    variant="transparent"
                    onClick={() =>
                      update({ ...initialValues, ecoAge: state.ecoAge })
                    }
                  >
                    <IconRefresh />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          </Flex>
          <Divider my="xs" />
          {!state.ecoAge && (
            <>
              <Button
                mt="md"
                variant="outline"
                radius="md"
                onClick={() => update({ ecoAge: 12 })}
                leftSection={<IconMathLower size={12} />}
              >
                {t("lessThan14Weeks")}
              </Button>

              <Button
                mt="md"
                radius="md"
                onClick={() => update({ ecoAge: 15 })}
                leftSection={<IconMathGreater size={12} />}
              >
                {t("greaterThan14Weeks")}
              </Button>
            </>
          )}
          {state.ecoAge && state.ecoAge < 14 && (
            <Grid>
              <Grid.Col span={12}>
                {ga && (
                  <Alert p="xs" variant="light" color="green">
                    <Text
                      fz={14}
                      fw={600}
                      display="flex"
                      style={{ alignItems: "center" }}
                    >
                      {t("ultrasoundGA")}: {ga ? `${fmf.daysToOW(ga)}` : "-"}{" "}
                      {ga && state?.ecoAge && state?.gaFromHc ? (
                        <Tooltip
                          maw={280}
                          label={t("gaCalcFromHCWarningMessage")}
                        >
                          <Box ml="4px" display="flex">
                            <IconAlertCircle color="red" size={20} />
                          </Box>
                        </Tooltip>
                      ) : null}
                    </Text>
                    {gaToday && (
                      <Text fw={600} fz={14}>
                        {t("gaToday")}: {gaToday}
                      </Text>
                    )}

                    {state?.fumc ? (
                      <Text fw={600} fz={14}>
                        {t("lmpC")}: {dayjs(state?.fumc).format("DD/MM/YYYY")}
                      </Text>
                    ) : null}
                  </Alert>
                )}
              </Grid.Col>

              <Grid.Col h="100%">
                <DatePickerInput
                  tabIndex={1}
                  clearable
                  maxDate={dayjs.utc().toDate()}
                  valueFormat="DD/MM/YYYY"
                  label={t("ultrasoundDate")}
                  value={
                    state.fechaEco ? dayjs.utc(state.fechaEco).toDate() : null
                  }
                  onChange={(value) => {
                    update({
                      fechaEco: value ? dayjs.utc(value).toISOString() : value,
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                <UnitNumInput
                  inputMode="decimal"
                  tabIndex={2}
                  allowedDecimalSeparators={[".", ","]}
                  allowNegative={false}
                  allowUnitChange={false}
                  description={`${t("range")}: 1-84`}
                  label={t("crl")}
                  defaultUnit="mm"
                  updateValueOnUnitChange
                  value={state.crl}
                  onChange={(value) => {
                    update({ crl: value });
                  }}
                  error={state?.crl?.value && state.crl.value > 84}
                />
              </Grid.Col>
              <Grid.Col>
                <Flex gap={20} justify="space-between">
                  <NumberInput
                    inputMode="decimal"
                    tabIndex={3}
                    allowedDecimalSeparators={[".", ","]}
                    hideControls
                    label={t("leftUterineArteryPulsatilityIndex")}
                    description={t("fromXWeeks", { weeks: 11 })}
                    value={String(state.ipAutIz)}
                    onChange={(value) => update({ ipAutIz: Number(value) })}
                    w="100%"
                  />
                  <NumberInput
                    inputMode="decimal"
                    tabIndex={4}
                    allowedDecimalSeparators={[".", ","]}
                    hideControls
                    label={t("rightUterineArteryPulsatilityIndex")}
                    description={t("fromXWeeks", { weeks: 11 })}
                    value={String(state.ipAutDer)}
                    onChange={(value) => update({ ipAutDer: Number(value) })}
                    w="100%"
                  />
                </Flex>
                {ipAutP && (
                  <Alert
                    p="xs"
                    variant="light"
                    color={ipAutP > 95 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("uterineArteryPulsatilityIndexCentile")}: {ipAutP}
                    </Text>
                  </Alert>
                )}
              </Grid.Col>
            </Grid>
          )}
          {state.ecoAge && state.ecoAge >= 14 && (
            <Grid>
              <Grid.Col span={12} order={{ base: 1 }}>
                {ga && (
                  <Alert p="xs" variant="light" color="green">
                    <Flex gap={20} wrap="wrap" pos="relative">
                      <Text
                        fz={14}
                        display="flex"
                        style={{ alignItems: "center" }}
                        fw={600}
                      >
                        {t("ultrasoundGA")}: {ga ? `${fmf.daysToOW(ga)}` : "-"}{" "}
                        {ga && state?.ecoAge && state?.gaFromHc ? (
                          <Tooltip label={t("gaCalcFromHCWarningMessage")}>
                            <Box ml="4px" display="flex">
                              <IconAlertCircle color="red" size={20} />
                            </Box>
                          </Tooltip>
                        ) : null}
                      </Text>
                      {gaToday && (
                        <Text fw={600} fz={14}>
                          {t("gaToday")}: {gaToday ?? "-"}
                        </Text>
                      )}
                    </Flex>
                  </Alert>
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 2, md: 2 }}>
                <DatePickerInput
                  autoFocus
                  tabIndex={1}
                  clearable
                  maxDate={dayjs.utc().toDate()}
                  valueFormat="DD/MM/YYYY"
                  label={t("lmpC")}
                  value={state.fumc ? dayjs.utc(state.fumc).toDate() : null}
                  onChange={(value) => {
                    update({
                      gaFromHc: false,
                      fumc: value ? dayjs.utc(value).toISOString() : value,
                    });
                  }}
                  error={
                    state?.gaFromHc &&
                    state?.fumc && (
                      <span style={{ color: "black" }}>{t("calcFromHC")}</span>
                    )
                  }
                  styles={
                    state?.gaFromHc && state?.fumc
                      ? { input: { borderColor: "#ffbebe" } }
                      : {}
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 3, md: 4 }}>
                <DatePickerInput
                  tabIndex={2}
                  clearable
                  maxDate={dayjs.utc().toDate()}
                  valueFormat="DD/MM/YYYY"
                  label={t("ultrasoundDate")}
                  value={
                    state.fechaEco ? dayjs.utc(state.fechaEco).toDate() : null
                  }
                  onChange={(value) => {
                    update({
                      fechaEco: value ? dayjs.utc(value).toISOString() : value,
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 4, md: 6 }}>
                <Fieldset legend={t("biometry")} p="xs">
                  <Flex gap="xs">
                    <UnitNumInput
                      inputMode="decimal"
                      tabIndex={3}
                      allowedDecimalSeparators={[".", ","]}
                      allowNegative={false}
                      allowUnitChange={false}
                      description={`${t("range")}: 1-499`}
                      label={t("HC")}
                      defaultUnit="mm"
                      updateValueOnUnitChange
                      value={state.hc}
                      onChange={(value) => update({ hc: value })}
                      error={state?.hc?.value && state.hc.value > 499}
                    />
                    <UnitNumInput
                      inputMode="decimal"
                      tabIndex={4}
                      allowedDecimalSeparators={[".", ","]}
                      allowNegative={false}
                      allowUnitChange={false}
                      description={`${t("range")}: 1-499`}
                      label={t("AC")}
                      defaultUnit="mm"
                      updateValueOnUnitChange
                      value={state.ac}
                      onChange={(value) => update({ ac: value })}
                      error={state?.ac?.value && state.ac.value > 499}
                    />
                    <UnitNumInput
                      inputMode="decimal"
                      tabIndex={5}
                      allowedDecimalSeparators={[".", ","]}
                      allowNegative={false}
                      allowUnitChange={false}
                      description={`${t("range")}: 1-99`}
                      label={t("FL")}
                      defaultUnit="mm"
                      updateValueOnUnitChange
                      value={state.fl}
                      onChange={(value) => update({ fl: value })}
                      error={state?.fl?.value && state.fl.value > 99}
                    />
                  </Flex>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 5, md: 8 }}>
                <UnitNumInput
                  inputMode="decimal"
                  tabIndex={6}
                  allowedDecimalSeparators={[".", ","]}
                  allowUnitChange={false}
                  label={t("estimatedFetalWeight")}
                  defaultUnit="g"
                  updateValueOnUnitChange
                  value={state?.efw}
                  parseValue={(value) =>
                    value ? Number(value.toFixed(4)) : value
                  }
                  onChange={(value) => update({ efw: value })}
                />
                {efwP && (
                  <Alert
                    p="xs"
                    variant="light"
                    color={efwP > 95 || efwP < 5 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("estimatedFetalWeightCentila")}: {efwP}
                    </Text>
                  </Alert>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 6, md: 3 }}>
                <Flex gap={20} justify="space-between">
                  <NumberInput
                    inputMode="decimal"
                    tabIndex={7}
                    allowedDecimalSeparators={[".", ","]}
                    hideControls
                    label={t("leftUterineArteryPulsatilityIndex")}
                    description={t("fromXWeeks", { weeks: 11 })}
                    value={String(state.ipAutIz)}
                    onChange={(value) => update({ ipAutIz: Number(value) })}
                    w="100%"
                  />
                  <NumberInput
                    inputMode="decimal"
                    tabIndex={8}
                    allowedDecimalSeparators={[".", ","]}
                    hideControls
                    label={t("rightUterineArteryPulsatilityIndex")}
                    description={t("fromXWeeks", { weeks: 11 })}
                    value={String(state.ipAutDer)}
                    onChange={(value) => update({ ipAutDer: Number(value) })}
                    w="100%"
                  />
                </Flex>
                {ipAutP && (
                  <Alert
                    p="xs"
                    variant="light"
                    color={ipAutP > 95 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("uterineArteryPulsatilityIndexCentile")}: {ipAutP}
                    </Text>
                  </Alert>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 7, md: 5 }}>
                <NumberInput
                  inputMode="decimal"
                  tabIndex={9}
                  allowedDecimalSeparators={[".", ","]}
                  hideControls
                  label="IP Arteria Umbilical"
                  value={String(state.ipAU)}
                  onChange={(value) => update({ ipAU: Number(value) })}
                />
                {ipAUP && (
                  <Alert
                    p="xs"
                    variant="light"
                    color={ipAUP > 95 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("uaPICentile")}: {ipAUP}
                    </Text>
                  </Alert>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 8, md: 7 }}>
                <NumberInput
                  inputMode="decimal"
                  tabIndex={10}
                  allowedDecimalSeparators={[".", ","]}
                  hideControls
                  label={t("middleCerebralArteryPI")}
                  value={String(state.ipACM)}
                  onChange={(value) => update({ ipACM: Number(value) })}
                />
                {ipACMP && (
                  <Alert
                    p="xs"
                    pb={0}
                    variant="light"
                    color={ipACMP < 5 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("middleCerebralArteryPICentile")} : {ipACMP}
                    </Text>
                  </Alert>
                )}
                {relCerPlacentaria && cerebroPlacentalRatioCentile && (
                  <Alert
                    p="xs"
                    pt={0}
                    variant="light"
                    color={cerebroPlacentalRatioCentile < 5 ? "red" : "green"}
                  >
                    {relCerPlacentaria ? (
                      <Text fw={600} fz={14}>
                        {t("cpRatio")}: {relCerPlacentaria.toFixed(4)}
                      </Text>
                    ) : null}
                    {cerebroPlacentalRatioCentile ? (
                      <Text fw={600} fz={14}>
                        {t("cpRatioCentile")}: {cerebroPlacentalRatioCentile}
                      </Text>
                    ) : null}
                  </Alert>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6 }} order={{ base: 9, md: 9 }}>
                <NumberInput
                  inputMode="decimal"
                  tabIndex={11}
                  allowedDecimalSeparators={[".", ","]}
                  hideControls
                  label={t("doctusVenosusPI")}
                  value={String(state.ipDV)}
                  onChange={(value) => update({ ipDV: Number(value) })}
                />
                {ipDVP && (
                  <Alert
                    p="xs"
                    variant="light"
                    color={ipDVP > 95 ? "red" : "green"}
                  >
                    <Text fw={600} fz={14}>
                      {t("dvPICentile")}: {ipDVP}
                    </Text>
                  </Alert>
                )}
              </Grid.Col>
            </Grid>
          )}
          <Divider my="md"></Divider>
          <Group gap="2px">
            <Text size="xs">{t("byUsingTheCalcIAccept")} </Text>
            <Link href="https://alma-med.com/terms" target="_blank">
              <Text size="xs" c="blue">
                {t("termsAndconditions")}
              </Text>
            </Link>
          </Group>
        </Card>
      </Flex>
    </Box>
  );
}
